.main-bg {
  background-image: url(../img/landing.jpg) !important;
  transform: translateZ(0px) translateY(0px);
}

.main-content {
  top: 0 !important;
}

.wht {
  color: #fff;
}

.res-img {
  height: 260px;
}

@media (max-width: 991px) {
  .main2-header {
    background: #fff !important;
    .header-user-menu {
      ul {
        opacity: unset;
        visibility: visible;
        top: 40px;
      }
      .header-user-name {
        color: #151515 !important;
        span {
          img {
            filter: brightness(0) invert(1);
            -webkit-filter: brightness(0) invert(1);
          }
        }
      }
    }
    .logo-holder {
      top: 0 !important;
      height: 35px !important;
      margin-top: 0 !important;
      img {
        -webkit-filter: none !important;
        height: 100%;
        width: 100px !important;
      }
    }
    .wallet-mbs {
      margin-left: 40px;
    }
    .signups {
      border: 1px solid #151515 !important;
    }
    .signins {
      border: 1px solid #151515 !important;
      border-right: 0 !important;
    }
    .show-reg-form a {
      color: #151515 !important;
    }
  }

  .main-content {
    display: none !important;
  }
  .main-wraper {
    margin-top: 72px !important;
  }

  .card-bottom {
    margin-bottom: 20px !important;
  }
  .main-register-holder {
    .main-register {
      // margin: 10px 0px 90px 0px !important;
    }
  }
}

.card-bottom {
  margin-bottom: 0px;
}

.main2-header {
  background: linear-gradient(rgba(0, 0, 0, 0.36), rgba(255, 255, 255, 0));
  .header-user-menu {
    ul {
      opacity: unset;
      visibility: visible;
      top: 40px;
    }
    .header-user-name {
      color: #fff;
      span {
        img {
          filter: brightness(0) invert(1);
          -webkit-filter: brightness(0) invert(1);
        }
      }
    }
  }
  .logo-holder {
    top: 0 !important;
    height: 50px;
    img {
      -webkit-filter: brightness(0) invert(1);
      height: 100%;
      width: 150px;
    }
  }
  .show-reg-form {
    a {
      color: #ffffff;
    }
  }
  .signups {
    border: 1px solid #ffffff;
    border-left: 0;
    a {
      border-left: 1px solid #ffffff;
    }
  }

  .signins {
    border: 1px solid #ffffff;
    border-right: 0;
  }
  .main-logo {
    -webkit-filter: brightness(0) invert(1) !important;
  }
}

.bg-dark {
  background-color: #060c17 !important;
}

.main-donate {
  margin-top: 64px !important;
  .fli-intl-rhs {
    width: 400px !important;
  }
}

.main-about {
  .base-component {
    h1 {
      font-size: 48px !important;
      line-height: 1.25 !important;
      font-weight: 300 !important;
      text-align: center !important;
      margin: 0 auto !important;
      padding: 32px 15px 20px !important;
      &::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 96px;
        height: 4px;
        margin-top: 13px;
        background-color: #facf00;
      }
    }
    .sub-heading1 {
      margin: 0 auto 30px;
      text-align: center;
      padding: 0 15px;
      max-width: 1040px;
    }
  }
  .nowplace {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    p {
      margin: 0 0 10px;
    }
  }
  .blue20-breakline {
    position: relative;
    padding-bottom: 25px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
    }
    &::before {
      border-left: 40px solid transparent;
      border-right: 40px solid transparent;
      border-top: 40px solid rgba(39, 47, 56, 0.2);
    }
    &::after {
      border-left: 39px solid transparent;
      border-right: 39px solid transparent;
      border-top: 39px solid #fff;
    }
    div {
      margin: 20px 0 40px;
      width: 100%;
      border-top: 1px solid rgba(39, 47, 56, 0.2);
    }
  }
  .our-vision-mission-ctn {
    position: relative;
  }
  .our-vision-mission-ctn.with-image-section .vision-mission-grid {
    padding: 0 40px;
  }
  .our-vision-mission-ctn .vision-mission-grid {
    width: 49.5%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
  }
  .our-vision-mission-ctn .vision-mission-grid-img img {
    max-height: 100%;
    display: block;
    margin: auto;
    width: 100%;
  }
  .our-vision-mission-ctn .vision-mission-grid-img {
    margin-bottom: 10px;
  }
  .our-vision-mission-ctn.with-image-section .vision-mission-grid-info {
    padding: 10px 5% 0;
  }
  .section-wrapper,
  .section-grey-wrapper,
  .section-blue-wrapper,
  .section-full-wrapper,
  .section-bottom-wrapper {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .our-values-ctn .content-about-grid {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 25px;
  }
  .our-people-ctn .content-about-grid,
  .our-values-ctn .content-about-grid {
    text-align: center;
  }
  .our-values-ctn .content-about-grid-img img {
    max-height: 100%;
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .our-people-ctn .content-about-grid-info,
  .our-values-ctn .content-about-grid-info {
    padding: 15px 0 15px;
  }
  .our-people-ctn .content-about-label,
  .our-values-ctn .content-about-label {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 5px;
  }
  .bodytext3 {
    line-height: 30px;
  }
  .content-body .container {
    max-width: 1080px;
    margin: 0 auto;
  }
  .our-values-ctn .content-about-grid-img {
    height: 150px;
  }
  .our-vision-mission-ctn.with-image-section .vision-mission-separator {
    display: none;
  }
  .heading2,
  .heading3 {
    font-size: 32px;
    line-height: 1.2em;
    font-weight: 300;
    text-align: center;
  }
  .our-vision-mission-ctn.with-image-section .vision-mission-grid-label {
    margin-bottom: 10px;
  }
  .start-top {
    text-align: center;
    p {
      margin: 0 0 10px;
    }
  }
}

.main-register {
  .custom-form {
    label {
      // width: auto !important;
      margin-top: 10px;
    }
    input {
      position: relative;
      z-index: 2;
      margin-bottom: 0px;
    }
  }
}

.error-warning {
  color: #fd1f1f;
  font-size: 12px;
  top: 0;
  // font-family: "Roboto";
}

.flight-desc {
  h2,
  p {
    // margin-bottom: 10px;
  }
}

.footer-block {
  h6 {
    color: #ffffff;
  }
}

.otp-modal {
  overflow-y: hidden !important;
  .modal-dialog {
    max-width: 490px !important;
    // margin-top:200px !important;
    .modal-content {
      border-radius: 0 !important;
      border: 0 !important;
      .otp-close {
        text-align: right !important;
        padding: 16px 16px 0px 0px !important;
        button {
          color: black !important;
          font-size: 22px !important;
        }
      }
      .otp-box {
        padding: 0px 61px 40px 61px !important;
        .otp-heading {
          color: black !important;
          font-family: "Roboto" !important;
          font-size: 22px !important;
          font-weight: bold;
          text-align: center !important;
        }
        .otp-text {
          color: black !important;
          font-family: "Roboto" !important;
          font-size: 14px !important;
          text-align: center !important;
          padding: 16px 0px 24px 0px !important;
        }
        .otp-layout {
          .otp-input {
            width: 100% !important;
            border-radius: 0 !important;
            // border:0 !important;
            border-width: 0px 0px 1px 0px !important;
            border-bottom: 1px solid grey;
            margin-right: 15px !important;
            color: black !important;
            font-size: 15px !important;
            box-sizing: border-box !important;
            -webkit-box-sizing: border-box !important;
            &:focus {
              // outline-offset: -2px !important;
              // outline : -webkit-focus-ring-color auto 1px !important;
              border-bottom: 2px solid rgba(0, 150, 255, 1) !important;
            }
          }
          .otp-error {
            border-bottom: 1px solid red !important;
          }
        }
        .otp-incorrect {
          color: red !important;
          font-size: 14px !important;
          text-align: center !important;
          padding-top: 10px !important;
        }
        .otp-resend {
          text-align: center !important;
          padding: 10px 0px 0px 0px !important;
          button {
            color: black !important;
            font-family: "Roboto" !important;
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

.successOtp {
  width: 93% !important;
  margin: auto !important;
  background-color: #ffffff;
  position: relative;
  padding: 0px 66px 25px 66px;
  .check-box {
    padding: 0 !important;
    text-align: center !important;
    i {
      color: green !important;
      font-size: 70px !important;
    }
  }
  .text {
    text-align: center;
    font-size: 18px !important;
    font-family: "Roboto" !important;
    color: black !important;
    font-weight: 900 !important;
    padding: 40px 0px 40px 0px !important;
  }
}

@media (max-width: 620px) {
  .otp-modal {
    .modal-dialog {
      max-width: 490px !important;
      margin: 200px 20px 0px 20px !important;
      .modal-content {
        border-radius: 0 !important;
        border: 0 !important;
        .otp-close {
          text-align: right !important;
          padding: 16px 16px 0px 0px !important;
          button {
            color: black !important;
            font-size: 22px !important;
          }
        }
        .otp-box {
          padding: 0px 61px 40px 61px !important;
          .otp-heading {
            color: black !important;
            font-family: "Roboto" !important;
            font-size: 22px !important;
            font-weight: bold;
            text-align: center !important;
          }
          .otp-text {
            color: black !important;
            font-family: "Roboto" !important;
            font-size: 14px !important;
            text-align: center !important;
            padding: 16px 0px 24px 0px !important;
          }
          .otp-layout {
            .otp-input {
              width: 100% !important;
              border-radius: 0 !important;
              border: 0 !important;
              border-bottom: 1px solid grey !important;
              margin-right: 15px !important;
              color: black !important;
              font-size: 15px !important;
              padding: 0 !important;
            }
            .otp-error {
              border-bottom: 1px solid red !important;
            }
          }
          .otp-incorrect {
            color: red !important;
            font-size: 14px !important;
            text-align: center !important;
            padding-top: 10px !important;
          }
          .otp-resend {
            text-align: center !important;
            padding: 10px 0px 0px 0px !important;
            button {
              color: black !important;
              font-family: "Roboto" !important;
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
}

.auth-icon-box {
  text-align: center;
  .google-auth {
    margin-top: 10px;
    border-radius: 50% !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 4px 1px,
      rgba(0, 0, 0, 0.24) 0px 4px 2px 0px !important;
    div {
      margin: 0 !important;
      border-radius: 50% !important;
    }
    span {
      display: none !important;
    }
  }
  .facebook-auth {
    width: 40px !important;
    height: 42px !important;
    border-radius: 50% !important;
    background-color: #4267b2 !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 4px 1px,
      rgba(0, 0, 0, 0.24) 0px 4px 2px 0px !important;
    margin-left: 20px;
  }
}

.main-register-holder {
  min-height: 70vh;
  .main-register {
    .custom-form {
      border-bottom: 2px solid #eee !important;
      padding-bottom: 20px !important;
      // margin-top: 20px;
    }
  }
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.react-tel-input {
  width: 100%;
  // margin-bottom: 14px;
  .flag-dropdown {
    top: unset !important;
    height: 46px !important;
  }
  input {
    border: 1px solid #eee !important;
    background: #f7f9fb !important;
    width: 100% !important;
    padding: 14px 20px 14px 75px !important;
    border-radius: 6px !important;
    color: #666 !important;
    font-size: 13px !important;
    height: 46px !important;
  }
  .selected-flag {
    height: 44px !important;
  }
  .country-list {
    height: 240px !important;
  }
}

@media (max-width: 411px) {
  .main-register-holder {
    min-height: 82vh !important;
  }
}
@media (max-width: 768px) {
  .main-register-holder {
    min-height: 85vh !important;
  }
}
