@font-face {
    font-family: MuliFont;
    src: url("../assets/fonts/Muli-Regular.ttf")
}

.background-banner {
    height: 100vh;
    background-size: 100% 100%;
    background-image: url("../assets/images/gainon/gainon_swiggy_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100%;
}

/*
height: 100vh;
background-size: 100% 100%;
width: 100%;
background-image: url("../assets/images/gainon/gainon_swiggy_background.png");
background-repeat: no-repeat; */

.banner-head {
    font-size: 3rem;
    font-family: MuliFont;
    margin-bottom: 0.5rem;
}

.claim-banner {
    margin-left: 20px;

}

.banner-text span {
    font-family: MuliFont;
    font-size: 22px;
    display: grid;
}

.swiggy-logo {
    margin-left: 15px;
}

.banner-content {
    margin-top: 15%;
    margin-left: 25px;
}

.resend-otp {
    margin-left: 40px;
}

.submit-btn {
    margin-top: 40px;
    margin-bottom: 10px;
}

.get-otp-btn {
    margin-top: 40px;
    margin-bottom: 10px;
}

.sign-up-btn {
    margin-top: 40px;
    margin-bottom: 10px;
}

.landingPage-top {
    margin-top: 4%;
}

.hello {
    border-bottom: 3px solid #FFDC39;
}

@media only screen and (max-width:480px) {
    .background-banner {
        /* height: 100vh !important; */
        width: 100%;
        background-image: url("../assets/images/gainon/Gainon_Landing_Mobile_res.png");
        background-repeat: no-repeat;
        background-position: center;

    }

    .background-gradient {
        background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
        /* padding: 10px; */
        /* text-align: center; */
        /* border-radius: 10px; */
        width: 100%;
        display: flex;
        margin-right: 0 !important;
        margin-left: 0 !important;
        border-radius: 15px;
        min-height: 25rem !important;


    }

    .landingPage-top {
        margin-bottom: 18vh;
        margin-top: 18vh;
        width: 100%;
        display: flex;
        /* justify-content: center; */
        margin-right: 0;
        margin-left: 0;
    }

    .banner-head {
        font-family: MuliFont;
        font-size: 2.0rem;
    }

    .claim-banner {
        margin-left: 0;
        margin-top: 3rem !important;
    }

    .banner-text span {
        font-family: MuliFont;
        font-size: 18px;
        display: grid;
        white-space: pre-wrap;
    }

    .swiggy-logo {
        height: 90px;
        /* width: 50%; */
        justify-content: center;
        margin: 0;
    }

    .banner-content {
        margin-top: 18vh;
        justify-content: center;
        display: grid;
        align-items: center;
        margin-left: auto;

    }

    .resend-otp {
        margin-left: 0;
    }

    .submit-btn {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .get-otp-btn {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .sign-up-btn {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .sign-up-banner {
        text-align: center;
        width: 100%;
        /* margin-bottom: 25px;     */
    }

    .sign-up-banner h2 {
        /* font-size: 32px !important; */
        font-size: 20px !important;
        font-family: MuliFont;
        margin-left: 0px !important;
        margin-right: 0px !important;
        /* margin: 2px; */
        font-weight: 700;
        margin-top: 50px !important;
    }

    .navbar-header-main {
        justify-content: center;
    }

    .mobile-hide {
        display: none;
    }

    .mobile-view {
        margin-top: 100px;
    }

    .banner-text span br {
        display: none;
    }
}