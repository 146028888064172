@import '../core';

// .svg-wrapper {
//   height: rem(420px);
//   width: rem(540px);
//   padding: rem(32px 50px 20px);
//   color: $white;

//   @include media-breakpoint-down(md) {
//     height: rem(390px);
//   }

//   @include media-breakpoint-down(sm) {
//     height: rem(310px);
//     width: rem(250px);
//     padding: rem(14px 20px);
//     margin: 0 rem(10px);
//     text-align: left;
//   }
//   @include media-breakpoint-down(xs) {
//     margin: 0 rem(4px);
//   }
// }

.brand-wrap {
  margin-bottom: rem(14px);

  @include media-breakpoint-up(md) {
    margin-bottom: rem(36px);
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: rem(36px);
  }

  .company-logo {
    height: rem(28px);
    width: rem(28px);

    @include media-breakpoint-up(md) {
      height: rem(48px);
      width: rem(48px);
    }
    > img {
      width: 100%;
    }
  }

  .brand-text {
    font-size: rem(12px);
    line-height: rem(15px);
    font-weight: 800;

    @include media-breakpoint-up(md) {
      line-height: rem(23px);
      font-size: rem(18px);
    }
  }

  svg {
    height: rem(14px);
    width: rem(14px);
    @include media-breakpoint-up(md) {
      height: rem(24px);
      width: rem(24px);
    }
  }

  .rupee-text {
    font-size: rem(16px);
    line-height: rem(20px);
    font-weight: 800;

    @include media-breakpoint-up(md) {
      font-size: rem(28px);
      line-height: rem(35px);
    }
  }
}

.voucher-code-wrap,
.date-wrap,
.voucher-number-wrap {
  font-weight: 600;

  > .title {
    font-size: rem(10px);
    letter-spacing: rem(0.83px);
    color: rgba($color: $white, $alpha: 0.6);
    display: block;
    line-height: rem(13px);

    @include media-breakpoint-up(md) {
      letter-spacing: rem(1.17px);
      font-size: rem(14px);
      line-height: rem(18px);
    }
  }
}

.voucher-number-wrap {
  width: 100%;
  flex: 1;
  @include media-breakpoint-up(md) {
    width: 51%;
    flex: 0 0 51%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
    flex: 0 0 50%;
  }
}
.voucher-code-wrap {
  margin-bottom: rem(16px);

  @media (min-width: 400px) {
    margin-bottom: rem(20px);
  }
  @include media-breakpoint-up(md) {
    margin-bottom: rem(33px);
  }
}

.date-wrap {
  margin-bottom: rem(16px);
  @include media-breakpoint-up(md) {
    margin-bottom: rem(24px);
  }
}

.voucher-id,
.voucher-date {
  font-size: rem(12px);

  @include media-breakpoint-up(md) {
    font-size: rem(16px);
  }
}

.box-copy-code {
  // width: rem(200px);
  font-size: rem(10px);
  padding: rem(4px 16px);
  font-weight: 800;
  line-height: rem(18px);
  border: 2px dashed $white;
  border-radius: rem(4px);
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: none;
  word-break: break-all;

  @media (min-width: 400px) {
    font-size: rem(14px);
    padding: rem(12px 16px);
    // width: rem(210px);
  }

  @include media-breakpoint-up(md) {
    // width: rem(238px);
    padding: rem(14px 8px);
    font-size: rem(14px);
  }
  &.highlight {
    border-color: $red;
    color: $red;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    // transform: scale(1.2);
    // transition: transform 0.3s;
    // -webkit-transition: transform 0.3s;
    // -moz-transition: transform 0.3s;
    // -ms-transition: transform 0.3s;
    // -o-transition: transform 0.3s;
    // -webkit-transform: scale(1.2);
    // -moz-transform: scale(1.2);
    // -ms-transform: scale(1.2);
    // -o-transform: scale(1.2);
  }
}

.title-bottom {
  text-align: center;
  font-size: rem(8px);
  font-weight: 600;
  letter-spacing: 0;
  line-height: rem(13px);
  color: rgba($color: $white, $alpha: 0.6);

  @media (min-width: 400px) {
    font-size: rem(10px);
  }
  @include media-breakpoint-up(md) {
    font-size: rem(12px);
    line-height: rem(15px);
  }
}

.copy-code-wrap {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding-top: rem(4px);

  @media (min-width: 400px) {
    padding-top: rem(48px);
  }
  @include media-breakpoint-up(md) {
    justify-content: space-between;
    flex-direction: row;
    padding-top: rem(28px);
  }
}

.space {
  justify-content: center;
}
// .blue-wrapper {
//   background: url(../../images/gainon/voucher-blue-bg.svg) no-repeat center;
//   background-size: contain;

//   @include media-breakpoint-down(sm) {
//     background: url(../../images/gainon/blue-voucher-mobile-bg.svg) no-repeat
//       center;
//   }
// }

// .purple-wrapper {
//   background: url(../../images/gainon/purple-voucher-bg.svg) no-repeat center;
//   background-size: contain;

//   @include media-breakpoint-down(sm) {
//     background: url(../../images/gainon/purple-voucher-mobile-bg.svg) no-repeat
//       center;
//   }
// }
