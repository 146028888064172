// ::-webkit-scrollbar {
//   background: rgb(110, 110, 110);
// }

// ::-webkit-scrollbar-thumb {
//   background: rgb(180, 180, 180);
// }

.sidebar .res {
  display: none;
}

.sidebar-menu {
  width: 20%;
  height: 100%;
  position: fixed;
  background: #000;
  margin: 66px 0 0 0;
  z-index: 2;

  ul {
    width: 100%;
    height: 90%;
    overflow-y: auto;
    scrollbar-color: indigo;

    a {
      cursor: pointer;
      color: #fff;
      text-align: left;
      padding: 20px;
      border-bottom: 1px solid grey;
      text-decoration: none;

      display: block;
    }

    .bg-white {
      background: rgb(243, 242, 242);
      color: #333 !important;
    }

    li {
      cursor: pointer;
      color: #fff;
      text-align: left;
      padding: 0px 0px;
      border-bottom: 1px solid grey;

      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
      }

      a {
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid grey;
      }

      ul {
        overflow-y: initial;
        margin: 0px 0;
        width: 100%;

        a {
          background: rgb(243, 242, 242);
          text-decoration: none !important;
          color: black;
          border: none;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .sidebar-menu {
    width: 25%;
  }
}

@media (max-width: 1250px) {
  .sidebar-menu {
    width: 28%;
  }
}

@media (max-width: 1050px) {
  .sidebar .sidebar-menu {
    width: 100%;
    display: none;

    // ul::-webkit-scrollbar {
    //   display: none;
    // }
  }

  .sidebar .res {
    display: initial;
  }
}
