.admin-registered-user {
  width: 1000px;
}

.loader-center {
  position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 50%;
}

@media (max-width: 1500px) {
  .admin-registered-user {
    width: 900px;
  }
}

.onoffswitch-switch{
  height: 25px !important;
  width: 25px !important;
}
