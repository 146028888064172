.admin-dashboard {
  border: 1px solid grey;

  .title {
    background: black;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }

  .container {
    padding: 20px;
    display: flex;

    .item {
      flex: 1;
      border: 1px solid grey;
      padding: 20px;
      margin: 0 10px;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 1500px) {
  .admin-dashboard {
    width: 900px;
  }

  .admin-dashboard .container {
    width: 100%;

    .item {
      width: 16%;

      img {
        width: 100%;
      }
    }
  }
}

/* ChangePasswordPopup */
.field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0px 40px 0;

  label {
    margin: 0 0px 20px 0;
    font-weight: bold;
    width: 90%;
    text-align: left;
  }

  input {
    padding: 20px 25px;
    background-color: #e8f0fe !important ;
    border: none;
    border-radius: 5px;
    width: 90% !important;
  }
}

.submit-pass {
  background-color: #c82e29 !important;
  color: white !important;
  border: none;
  cursor: pointer;
  box-shadow: none;
  padding: 15px 20px;
  margin: auto;
  border-radius: 5px;
  width: 90%;
}



