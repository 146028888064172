.brand-coupon-edit .row-fluid .block .block-header {
    padding: 15px;
  }
  
  .brand-coupon-edit .row-fluid .block .cont {
    width: 100%;
  
    .add-coupon-main {
      width: 60% !important;
      margin: 20px auto !important;
  
      .main-row {
        display: flex;
        align-items: center;
  
        .name {
          flex: 1;
        }
  
        .val {
          flex: 3;
        }
  
        .action-center {
          width: 50% !important;
          margin: 20px auto !important;
          display: flex !important;
          align-items: center !important;
          justify-content: space-around;
  
          button,
          a {
            padding: 0 !important;
            margin: 0 !important;
            width: 40%;
            background: #c82e29 !important;
          }
        }
      }
    }
  }
  
  .brand-coupon-edit .row-fluid .block .block-header .pull-left {
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .brand-coupon-edit .row-fluid .block .add-coupon-main select,
  .brand-coupon-edit .row-fluid .block .add-coupon-main input {
    background-color: #e8f0fe !important;
  }
  

  @media (max-width: 1500px) {
    .brand-coupon-edit {
      max-width: 900px !important;
    }
  }
  
  @media (max-width: 1300px) {
    .brand-coupon-edit {
      max-width: 800px !important;
    }
  }
  