

.page-redeem {
  .card-brand {
    border-radius: rem(8px);
    -webkit-border-radius: rem(8px);
    -moz-border-radius: rem(8px);
    -ms-border-radius: rem(8px);
    -o-border-radius: rem(8px);

    @include media-breakpoint-up(lg) {
      padding: rem(12px 20px);
    }
    @include media-breakpoint-up(xl) {
      padding: rem(12px 40px);
    }
    .card-body {
      @include media-breakpoint-up(lg) {
        padding: rem(20px 0);
      }
      @include media-breakpoint-up(xl) {
        padding: rem(20px 20px 20px 0);
      }
      .redeem-content {
        flex-direction: column;
        margin-bottom: rem(22px);
        @include media-breakpoint-up(lg) {
          flex-direction: row;
          margin-bottom: rem(36px);
        }
      }
      .redeem-logo-wrap {
        @include media-breakpoint-down(md) {
          justify-content: unset !important;
        }
        .redeem-image-box {
          position: relative;
          max-width: rem(100px);
          width: rem(50px);
          overflow: hidden;
          &:after {
              content: "";
              display: block;
              padding-bottom: 100%;
            }
          img {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        span {
          font-size: rem(16px);
          line-height: rem(20px);
          @include media-breakpoint-up(lg) {
            font-size: rem(18px);
          line-height: rem(23px);
          }
          font-weight: 800;
        }
        margin-bottom: rem(16px);
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
      }
      p {
        font-size: rem(14px);
        font-weight: 600;
        letter-spacing: 0;
        line-height: rem(18px);
        opacity: 0.5;
        margin-bottom: rem(16px);
        @include media-breakpoint-up(lg) {
          margin-bottom: rem(24px);
        }
      }
    }
  }
  .pay-wrap {
    .btn {
      width: 100% !important;
      @include media-breakpoint-up(lg) {
        width: rem(320px) !important;
      }
    }
  }
}

.balance-blue-box {
  border: 1px solid $blue;
  padding: rem(8px);
  max-width: rem(220px);
  // height: rem(36px);
  background-color: rgba(72,134,255,0.08);
  border-radius: rem(4px);
  -webkit-border-radius: rem(4px);
  -moz-border-radius: rem(4px);
  -ms-border-radius: rem(4px);
  -o-border-radius: rem(4px);

  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }

  .balance-label {
    font-size: rem(14px);
    line-height: rem(18px);
    font-weight: normal;
  }
  span {
    font-size: rem(16px);
    line-height: rem(20px);
    font-weight: 600;
    > svg {
      margin-top: rem(-3px);
    }
  }
}

.redeem-balance-value {
  padding-left: 0;
  .form-check-label {
    font-size: rem(18px);
    font-weight: bold;
    line-height: rem(23px);
    border: 1px solid #DDDDDD;
    color: $gray-dk;
    background-color: $white;
    box-shadow: none;
    width: rem(105px);
    border-radius: rem(4px);
    padding: rem(14px 8px);
    margin-right: rem(8px);
    -webkit-border-radius: rem(4px);
    -moz-border-radius: rem(4px);
    -ms-border-radius: rem(4px);
    -o-border-radius: rem(4px);
    
    @include media-breakpoint-up(lg) { 
      font-size: rem(24px);
      line-height: rem(30px);
      width: rem(123px);
    }
    img {
      display: none;
    }
    svg {
      margin-top: rem(-4px);
    }
  }

  input[type=radio] {
    margin-top: 0;
    margin-left: 0;
    position: absolute!important;
    visibility: hidden!important;
  }
  input[type=radio]:checked+label {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.09);
    background-color: $white !important;
    border-color:  $blue !important;
    color: $gray-dk !important;
    img {
      display: block;
      width: rem(16px);
      height: rem(16px);
    }
  }
}
