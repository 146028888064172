#root {
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.body {
  @extend .position-relative;
}

.modal-open .container,
.modal-open .modal-blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.go-header {
  background-color: $white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  font-size: rem(14px);
  font-weight: 600;

  .navbar-brand {
    > img {
      width: 76px;
      height: auto;
    }
  }

  .box-money {
    margin-right: rem(60px);
    color: $gray-dk;
    font-size: rem(14px);
    font-weight: 600;
    line-height: rem(18px);
  }

  .box-money,
  .box-rupee {
    > i {
      font-size: rem(20px);
      color: $red;
      margin-right: rem(34px);
    }
  }
  .box-rupee {
    cursor: pointer;
  }

  .box-avtar {
    height: rem(37px);
    width: rem(37px);
    background-color: $black;
    @extend .font-weight-bold;
    color: $white;
    font-size: rem(16px);
    line-height: rem(20px);
  }

  .dropdown > a {
    align-items: center;
    @extend .text-decoration-none;
  }

  .dropdown-menu {
    width: rem(200px);
  }

  .dropdown-item {
    padding: rem(12px);

    > i {
      font-size: rem(20px);
    }
  }

  .btn-menu {
    min-width: rem(50px);
    padding: 0 rem(8px);
    &:focus {
      box-shadow: none;
    }

    > i {
      font-size: rem(20px);
    }
  }
}

.page {
  @extend .flex-grow-1;

  h1 {
    font-size: rem(18px);
    font-weight: bold;
    line-height: rem(23px);
  }
}

.bg-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
}

.gutter-sm {
  margin-right: rem(-8px);
  margin-left: rem(-8px);

  > .col,
  > [class*='col-'] {
    padding-right: rem(8px);
    padding-left: rem(8px);
  }
}

.font-weight-smbold {
  font-weight: 600 !important;
}
.text-12 {
  font-size: rem(12px) !important;
}
.text-14 {
  font-size: rem(14px) !important;
}
.text-16 {
  font-size: rem(16px) !important;
}
.text-18 {
  font-size: rem(18px) !important;
}
.text-22 {
  font-size: rem(22px) !important;
}
.text-24 {
  font-size: rem(24px) !important;
}
.text-30 {
  font-size: rem(30px) !important;
}
.go-main-footer {
  background-color: $white;
  color: rgba(#101011, 0.6);
  padding-top: rem(73px);
  @include media-breakpoint-down(sm) {
    padding-top: rem(28px);
  }

  .details {
    margin-top: rem(40px);
    @extend .text-14;
    line-height: rem(21px);

    @include media-breakpoint-down(sm) {
      margin-top: rem(20px);
    }
  }

  .link-txt {
    color: #101011;
    @extend .text-16;
    margin-bottom: rem(12px);
    font-weight: bold;
    line-height: rem(20px);
  }
}

.nav-importanat-link {
  .nav-link {
    padding: rem(12px) 0;
    color: rgba(#101011, 0.6);
    @extend .text-14;
    line-height: rem(18px);
  }
}

.contact-list {
  padding-top: rem(12px);
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }

  dt,
  dd {
    font-weight: 600;
    color: rgba(#101011, 0.6);
    @extend .text-14;
    line-height: rem(18px);
  }
  dd {
    line-height: rem(21px);
    margin-bottom: rem(12px);
  }
}
.go-footer {
  padding-top: rem(72px);
  color: rgba(#101011, 0.6);

  @include media-breakpoint-down(sm) {
    padding-top: rem(28px);
  }

  .footer-wrap {
    @extend .d-flex, .align-items-center, .text-14;
    // min-height: rem(62px);
    padding: rem(21px) 0;
    border-top: 2px solid #d8d9e4;
    color: rgba(#101011, 0.6);
    line-height: rem(18px);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start !important;
    }

    > div {
      @include media-breakpoint-down(sm) {
        order: 1;
      }
    }

    > .nav {
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }
  }
  .nav-link {
    color: rgba(#101011, 0.6);
  }
}

// Carousel Css
.carousel-indicators > li {
  height: rem(8px);
  width: rem(8px);
  border-radius: 50%;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.carousel-indicators {
  margin-bottom: 0;
}

.box-avtar {
  border-radius: 50%;
  @extend .d-flex,
    .align-items-center,
    .justify-content-center,
    .mx-auto,
    .overflow-hidden;
}

// Label Css
label,
.form-label {
  color: rgba($gray-dk, 0.5);
  font-size: rem(14px);
  font-weight: 600;
  line-height: rem(18px);
}

// Form Control Css
.form-control {
  padding: rem(14px 16px);
  border: 1px solid #dadada;
  height: rem(48px);
  line-height: rem(20px);
  color: $gray-dk;
  &:hover {
    border: 1px solid rgba(72, 134, 255, 0.5);
    background-color: #fcfcfc;
  }
  &:disabled {
    background-color: #f3f3f3;
  }
}
.form-control::-webkit-input-placeholder {
  color: rgba($gray-dk, 0.5);
}
.form-control::-moz-placeholder {
  color: rgba($gray-dk, 0.5);
}
.form-control:-ms-input-placeholder {
  color: rgba($gray-dk, 0.5);
}
.form-control:-moz-placeholder {
  color: rgba($gray-dk, 0.5);
}

.input-group {
  > .form-control {
    @extend .border-left-0;
    &:hover,
    &:focus {
      border-color: #e2e2e2;
    }
    &:hover {
      background-color: $white;
    }
  }
}

.input-group-prepend,
.input-group-append {
  > .input-group-text {
    background-color: $white;
  }
}

.go-modal {
  img {
    height: rem(60px);
    width: rem(60px);
  }
  p {
    font-weight: 600;
    color: #848484;
  }
  span {
    color: $gray-dk;
  }
  .box-otp {
    margin-bottom: rem(20px);
    justify-content: center;
  }
  .otp-input {
    height: rem(48px);
    width: rem(82px) !important;
    margin: 0 rem(12px);
    border: 1px solid #dadada;
    border-radius: rem(4px);
    @include media-breakpoint-down(sm) {
      width: rem(48px) !important;
    }
  }
}

i {
  vertical-align: middle;
}
.rupee-img {
  height: rem(14px);
  width: rem(14px);
}

.box-sidebar {
  display: block;
  position: fixed;
  top: rem(52px);
  z-index: 9999;
  bottom: 0;
  left: 0;
  background-color: $white;
  padding: rem(8px 16px 16px);
  width: 100vw;
  transform: translateX(-100vw);
  border-top: 1px solid #979797;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  pointer-events: all;

  &.open {
    transform: translateX(0);
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
  }

  .nav-link {
    color: $gray-dk;
    font-weight: 600;
    line-height: rem(18px);
  }
}

// container scss
@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}

.go-table {
  .grqoVc {
    border: 1px solid #dddddd;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;
  }
  .icon-arrow-right {
    font-size: rem(14px);
  }
  .up {
    transform: rotate(-45deg);
    color: #ff9f19;
  }
  .down {
    transform: rotate(-230deg);
    color: #2ac009;
  }
}

.error-warning,
.otp-incorrect {
  color: $red;
}

// .abc {
//   height: 250px !important;
//   width: 540px !important;
//   border: 1px solid red;
// }
.icon-arrow-right {
  display: inline-block;
  font-weight: bold;
  font-size: rem(20px);
  &.shop-arrow {
    transform: rotate(-45deg);
    color: #ff9f19;
  }
  &.redeem-arrow {
    transform: rotate(135deg);
    color: #2ac009;
  }
}

///@include

.box-vouchcer {
  position: relative;
  width: rem(290px);
  // border: 1px solid red;
  // margin: rem(10px);
  // width: rem(600px);
  background-image: url(../images/gainon/bg-mobile.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: left top;
  color: $white;
  // 560px X  420px
  // cell 266 x 311
  @media (min-width: 400px) {
    width: rem(360px);
  }
  @include media-breakpoint-up(sm) {
    width: rem(560px);
    background-image: url(../images/gainon/bg-boxvoucher.svg);
  }
  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-up(lg) {
  }
  .content {
    position: absolute;
    top: rem(14px);
    left: rem(20px);
    bottom: rem(16px);
    right: rem(20px);
    // border: 1px solid greenyellow;
    @include media-breakpoint-up(md) {
      top: rem(32px);
      left: rem(50px);
      bottom: rem(32px);
      right: rem(50px);
    }

    @include media-breakpoint-up(lg) {
      top: rem(32px);
      left: rem(40px);
      bottom: rem(16px);
      right: rem(40px);
    }
  }

  &:after {
    content: '';
    display: block;
    // padding-top: (420 / 560) * 100%;
    padding-top: (311 / 266) * 100%;

    @media (min-width: 400px) {
      width: rem(360px);
    }

    @include media-breakpoint-up(sm) {
      width: rem(560px);
      background-image: url(../images/gainon/bg-boxvoucher.svg);
      padding-top: (420 / 560) * 100%;
    }
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
    }
  }
}
