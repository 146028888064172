.editor{
    height: 200px;
    border:  1px solid #ced4da;
    .public-DraftStyleDefault-ltr{
        margin:0 !important;
    }
}

.toolbar{
    border:  1px solid #ced4da !important;
}
.promo-apply-msg{
    padding-left: 20px;
    color: green;
    text-align: center;
    i{
        padding-right: 10px;
    }
}

.brands-btn{
    button{
        background-color: #c82e29 !important;
    }
    a{
        background-color: #c82e29 !important;
    }
}
.promocode{
    input{
        border: 1px solid #eee !important;
        background: #F7F9FB !important;
        width: auto;
        padding: 14px 20px 14px 20px !important;
        border-radius: 6px !important;
        color: #666 !important;
        font-size: 13px !important;
        height: 46px !important;
    }
    .see-promocode{
        color: red;
        cursor: pointer;
    }
    .see-promocode:hover {
        text-decoration: underline;
        // color: blue;
    }
}
.promocode-modal{
    input{
        border: 2px dotted grey;
        padding-left: 10px;
        font-weight: 600;
        width: 150px;
        height: 32px;
        width: 150px;
        font-size: 13px !important;
    }
    .copy-btn{
        height: 33px;
        background-color: rgb(255, 87, 34);
        width: auto;
        padding: 0px  10px;
        color: #ffffff;
    }
    .description{
        .view-detail{
            color: #FF0000;
            cursor: pointer;
            float: right;
        }
        .view-detail:hover{
            // color: blue;
            text-decoration: underline;
        }
        .term-condition{
            font-size: 10px;
        }
    }
}
@media (max-width: 550px) {
    .promocode-apply{
        .promocode{
            flex: 0 0 100% !important;
            max-width: 100% !important;
            padding: 0px !important;
            input{
                border: 1px solid #eee !important;
                background: #F7F9FB !important;
                width: 170px !important;
                padding: 14px 20px 14px 20px !important;
                border-radius: 6px !important;
                color: #666 !important;
                font-size: 13px !important;
                height: 46px !important;
            }
            .see-promocode{
                color: red;
                cursor: pointer;
            }
            .see-promocode:hover {
                text-decoration: underline;
                color: blue;
            }
            .col-sm-4{
                text-align: center !important;
                padding: 10px 0px !important;
            }
            .col-sm-8{
                padding: 0px !important;
            }
        }
        .chkout-btn{
            padding: 0px !important;
            button{
                margin: 0px !important;
                width: 100% !important;
            }
        }
    }
}


@media (max-width: 768px) and (min-width: 551px) {
    .promocode-apply{
        .promocode{
           
            input{
                width:140px;
            }
            .see-promocode{
               
            }
            .see-promocode:hover {
                
            }
            .col-sm-4{
                
            }
            .col-sm-8{
                
            }
        }
        .chkout-btn{
            
        }
    }
}

