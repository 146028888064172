@import "../core";

.brand-box-wrap{
  // min-width: 210px;
  // max-width: 240px;
  display: grid;
  grid-gap: rem(16px);
  grid-template-columns: repeat(auto-fill, minmax(210px, .5fr));

  @include media-breakpoint-down(md) {
    grid-gap: rem(10px);
    grid-template-columns: repeat(auto-fill, minmax(140px, .5fr));
  }

}

.brand-box{
  position: relative;
  border: 1px solid #E3E3E3 ;
  background-color: $white;
  border-radius: rem(8px);
  padding: rem( 22px 18px);
  // margin-bottom: rem(16px);
  min-height: rem(270px);
  font-size: rem(14px);

  @extend .d-flex, .flex-column, .align-items-center, 
  .justify-content-between, .text-center;
    
  @include media-breakpoint-down(md) {
    font-size: rem(12px);
  }

  p{
    margin: rem(0 0 12px 0);
    color: rgba($gray-dk, .5);
  }
  
  .brand-logo{
    width: 60%;
  }

  > strong{
    font-weight: bold;
    font-size: rem(18px);
    margin-bottom: rem(16px);
      
    @include media-breakpoint-down(md) {
      font-size: rem(14px);
    }
  }
    
  &:hover{

    > strong{
      margin-bottom: rem(76px);
    }

  }
  
}

.brand-logo{
  position: relative;
  max-width: rem(200px);
  width: 100%;
  overflow: hidden;
  
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  img{
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.voucher-box{
  position: relative;
  background: transparent;
  border-style: solid;
  border-width: 16px;
  border-image: url('../../images/gainon/dots-2.svg') 18 round;
  font-size: rem(14px);
  cursor: pointer;
  border-image-outset: 5px;
  background: $white;
  margin: rem(5px 4px);
  
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  p{
    margin: 0;
    color: rgba($gray-dk, .5);
  }
  .content {
    background-color: white;
    position: absolute;
    padding: rem(4px);
    min-width: 100%;
    min-height: 100%;
    @extend .d-flex, .flex-column, .align-items-center, 
  .justify-content-center, .text-center;
    > strong {
      font-size: rem(18px);
      line-height: rem(23px);
      font-weight: normal;
      margin-bottom: rem(16px);
      opacity: 0.65;
    
      @include media-breakpoint-down(md) {
        font-size: rem(14px);
        line-height: rem(18px);
        margin-bottom: rem(10px);
      }
      @include media-breakpoint-up(lg) {
        font-weight: bold;
        opacity: 1;
      }
    }
  }
  .brand-logo{
    width: 60%;
    // margin-bottom: rem(18px);
  }
  div, p, strong {
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  &:hover{
    p {
      margin-bottom: rem(76px);
      @include media-breakpoint-down(sm) {
        margin-bottom: rem(55px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: rem(100px);
      }
    }
    strong {
      margin-bottom: rem(76px);
      @include media-breakpoint-down(sm) {
        margin-bottom: rem(55px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: rem(100px);
      }
    }
    .content {
      @include media-breakpoint-down(md) {
        padding: rem(0 4px);
      }
    }
    @include media-breakpoint-down(sm) {
      border-image-outset: 7px;
      .button-wrap {
        .btn {
          font-size: rem(10px);
          padding: rem(6px);
        }
      }
    }
  }

}

.brand-box div, strong{
  -webkit-transition: all .3s;
  transition: all .3s;
}

.brand-box, .voucher-box { 

  .button-wrap { 
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: rem(18px);
    right: rem(18px);
  }
  &:hover{
    box-shadow: 0 8px 24px 0 rgba(0,0,0,0.16);
    
    .brand-logo{
      width: 40%;
    }

    .button-wrap { 
      opacity: 1;
      visibility: visible;
      bottom: rem(22px);
    }
  }

  .btn {
    min-width: rem(50px);
  }
}
.shop-banner-img {
  width: 100%;
  height: auto;
}