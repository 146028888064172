$font-path: '../fonts' !default;

@font-face {
  font-family: 'Muli';
  src: url('#{$font-path}/Muli-Bold.eot');
  src: url('#{$font-path}/Muli-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$font-path}/Muli-Bold.woff2') format('woff2'),
      url('#{$font-path}/Muli-Bold.woff') format('woff'),
      url('#{$font-path}/Muli-Bold.svg#Muli-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url('#{$font-path}/Muli-Regular.eot');
  src: url('#{$font-path}/Muli-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$font-path}/Muli-Regular.woff2') format('woff2'),
      url('#{$font-path}/Muli-Regular.woff') format('woff'),
      url('#{$font-path}/Muli-Regular.svg#Muli-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url('#{$font-path}/Muli-SemiBold.eot');
  src: url('#{$font-path}/Muli-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('#{$font-path}/Muli-SemiBold.woff2') format('woff2'),
      url('#{$font-path}/Muli-SemiBold.woff') format('woff'),
      url('#{$font-path}/Muli-SemiBold.svg#Muli-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url('#{$font-path}/Muli-ExtraBold.eot');
  src: url('#{$font-path}/Muli-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('#{$font-path}/Muli-ExtraBold.woff2') format('woff2'),
      url('#{$font-path}/Muli-ExtraBold.woff') format('woff'),
      url('#{$font-path}/Muli-ExtraBold.svg#Muli-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

