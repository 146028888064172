@import "../core";

.page-brand {
    padding: rem(40px 0);
    @include media-breakpoint-down(sm) { 
        padding: rem(0 0 40px);
    }
    .card-brand-type {
        border: 1px solid #DDDDDD;
        border-radius: rem(8px);
        -webkit-border-radius: rem(8px);
        -moz-border-radius: rem(8px);
        -ms-border-radius: rem(8px);
        -o-border-radius: rem(8px);
        .card-body{ 
                h4 {
                    font-size: rem(18px);
                    line-height: rem(23px);
                    font-weight: bold;
                    @include media-breakpoint-down(sm) {  
                        font-size: rem(16px);
                        line-height: rem(20px);  
                    }
                }

                p {
                    font-size: rem(14px);
                    line-height: rem(26px);
                    font-weight: 600;
                    @include media-breakpoint-down(sm) {  
                        font-size: rem(12px);
                        line-height: rem(20px);  
                    }
                }
        }
        &.card-shop {
            margin-top: rem(15px);
            @include media-breakpoint-down(sm) { 
                margin-top: rem(20px);
            }
            .card-body{
                padding: rem(24px 36px);
                @include media-breakpoint-down(sm) { 
                    padding: rem(12px);
                }
                .icon-img {
                    width: rem(72px);
                    height: 100%;
                    margin-right: rem(24px);
                    @include media-breakpoint-down(sm) { 
                        width: rem(40px);
                        margin-right: rem(12px);
                    }
                }
            }  
        }
        &.card-redeem {
            box-shadow: 0 4px 8px 0 rgba($black,0.04);
            margin-top: rem(20px);
            .redeem-icon-img{
                border-right: 1px solid #dddddd;
                border-radius: rem(8px 0 0 8px);
                -webkit-border-radius: rem(8px 0 0 8px);
                -moz-border-radius: rem(8px 0 0 8px);
                -ms-border-radius: rem(8px 0 0 8px);
                -o-border-radius: rem(8px 0 0 8px);
                width: 100%;
                height: rem(158px);

                @include media-breakpoint-down(sm) {
                    border-bottom: 1px solid #dddddd;
                    border-right: none;
                    border-radius: rem(8px 8px 0 0);
                    -webkit-border-radius: rem(8px 8px 0 0);
                    -moz-border-radius: rem(8px 8px 0 0);
                    -ms-border-radius: rem(8px 8px 0 0);
                    -o-border-radius: rem(8px 8px 0 0);
                    height: 100%;
                }
            }
        }
    }

    .card-brand {
        border: 1px solid #DDDDDD;
        padding: rem(12px 40px);
        border-radius: rem(12px);
        -webkit-border-radius: rem(12px);
        -moz-border-radius: rem(12px);
        -ms-border-radius: rem(12px);
        -o-border-radius: rem(12px);
        box-shadow: 0 4px 8px 0 rgba($black,0.04);
        @include media-breakpoint-down(sm) {   
            padding: 0;
            border: none;
            box-shadow: none;
            margin-left: rem(-15px);
            margin-right: rem(-15px);
        }
     .card-body {
        @include media-breakpoint-down(sm) {  
            padding: rem(20px 15px);
        }
     }
        .brand-title {
            .brand-img-box {
                position: relative;
                max-width: rem(100px);
                width: rem(50px);
                overflow: hidden;
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                  }
                >img {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

            }
           
            .media-body {
                >h5 {
                    font-size: rem(18px);
                    line-height: rem(23px);
                    font-weight: 800;
                    @include media-breakpoint-down(sm) {  
                       font-size: rem(16px);
                       line-height: rem(20px);
                    }
                }
            }
        }
        .balance-details {
            .balance-text {
                font-size: rem(14px);
                font-weight: 600;
                line-height: rem(18px);
                @include media-breakpoint-down(sm) { 
                    font-size: rem(12px);
                    line-height: rem(15px);
                }
                span {
                    font-weight: bold;
                    > svg {
                        margin: rem(1px 3px 3px);
                    }
                }
            }
            .tooltip-img {
                width: rem(16px);
                height: rem(16px);
                margin-top: rem(-2px);
            }
        }
        .pay-wrap {
            >.btn {
                margin-bottom: rem(20px);
                width: 100%;
                @include media-breakpoint-up(xl) { 
                    width: rem(320px);
                }
            }
            span, a{
                font-size: rem(14px);
                line-height: rem(18px);
                font-weight: 600;
            }
            span {
                opacity: 0.5;
            }
            .click-here-box {
                cursor: pointer;
            }
        }
        .brand-alert {
            border: 1px solid #FFB730;
            background-color: #FEF0D6;
            padding: rem(8px);
            img {
                width: rem(16px);
                height: rem(16px);
            }
            p {
                font-size: rem(12px);
                line-height: rem(17px);
                margin-bottom: 0;
            }
        }
        .outstanding-amount {
            h6, span {
            font-size: rem(14px);
            font-weight: 600;
            line-height: rem(18px);
            @include media-breakpoint-down(sm) { 
                font-size: 12px;
                line-height: rem(15px);
            }
            }
            span {
                font-weight: bold;
                opacity: unset;
            }
            h6 {
                display: inline-block;
                opacity: 0.5;
            }
        }
        .balance-value {
            margin-bottom: rem(40px);
            width: rem(130px) !important;    
            .form-control {
                font-size: rem(18px) !important;
                line-height: rem(23px) !important;
                font-weight: 600 !important;
            }
        }
        .input-group {
            border: 1px solid #dadada;
            border-radius: rem(4px);
            -webkit-border-radius: rem(4px);
            -moz-border-radius: rem(4px);
            -ms-border-radius: rem(4px);
            -o-border-radius: rem(4px);
            
            .form-control {
                font-size: rem(22px);
                line-height: rem(28px);
                font-weight: bold;
            }
            .form-control, .rupee-icon  {
                border: none;
                padding: rem(6px 2px 6px 10px);
                > svg {
                    opacity: 0.5;
                }
            }
            @include media-breakpoint-down(sm) { 
                margin-left: unset !important;
                width: 100%; 
                margin-top: rem(5px);
            }
            @media only screen 
            and (min-device-width : 768px) 
            and (max-device-width : 1024px) 
            and (orientation : landscape) {
                width: auto;
            }
        }
        .coupon-form-input {
            margin-bottom: rem(32px);
            flex-direction: column !important;
            @include media-breakpoint-up(xl) { 
                flex-direction: row !important;
            }
            .form-label {
                padding: rem(15px 0 15px 15px);
            }
        }
   
    }

    .terms-tab {
        padding-left: 0;
        list-style-type: none;
        li {
            @include media-breakpoint-down(sm) { 
                font-size: rem(14px);
            }
        }
    }
    .nav-gotabs {
        @include media-breakpoint-down(sm) { 
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
        }
        .nav-link {
            @include media-breakpoint-down(sm) { 
                font-size: rem(14px);
                line-height: rem(18px);
                white-space: nowrap;
                padding: rem(8px 0);
            } 
        }
    }
    .brand-gotabs {
        @include media-breakpoint-down(sm) { 
           background-color: #F7F8FC;
           margin-top: 0 !important;
           padding: rem(18px 15px);
        }
        @include media-breakpoint-down(md) { 
             margin-top: rem(20px);
        }
    }

    .voucher-wrap {
        svg {
            width: 100%;
        }
        i {
            font-size: rem(20px);
            font-weight: 800;
            line-height: rem(25px);
            color: $gray-dk;
            opacity: 0.3;
            @include media-breakpoint-down(sm) {  
                font-size: rem(16px);
                line-height: rem(20px);
            }
        }
        .number {
            font-size: rem(24px);
            font-weight: 800;
            line-height: rem(30px);
            @include media-breakpoint-down(sm) {  
                font-size: rem(20px);
                line-height: rem(25px);
            }
        }
    }
}