@import '../core';

.rewards-card-wrap {
  display: grid;
  grid-gap: rem(20px);
  grid-template-columns: repeat(auto-fill, minmax(240px, 0.5fr));
  grid-auto-columns: minmax(240px, 0.5fr);
  justify-content: center;
}

.rewards-box {
  perspective: 1000px;

  &:hover {
    .blue-card,
    .purple-card,
    .red-card,
    .black-card {
      transform: rotateX(30deg);
    }

    .btn-flip {
      display: block;
    }
  }
}

.reward-card {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: left top;
  position: relative;
  color: $white;
  width: rem(240px);
  margin-bottom: rem(12px);
  transition: transform 0.6s;
  transform-style: preserve-3d;

  @include media-breakpoint-up(sm) {
    margin-bottom: rem(30px);
  }

  &.is-flipped {
    transform: rotateX(180deg);
  }

  .content {
    position: absolute;
    top: rem(16px);
    left: rem(16px);
    bottom: rem(14px);
    right: rem(16px);

    .rewards-heading {
      font-size: rem(14px);
      line-height: rem(20px);
    }
    .rewards-heading-burn {
      line-height: rem(20px);
      color: #333333;
    }

    .upto-txt {
      font-size: rem(12px);
      line-height: rem(20px);
    }

    svg {
      width: rem(20px);
      height: rem(20px);
    }

    .rupee-txt {
      font-size: rem(24px);
      font-weight: 900;
      line-height: rem(30px);
    }

    .company-logo {
      height: rem(30px);
      width: rem(30px);
      border-radius: rem(2px);
      background-color: $white;
      padding: rem(2px);

      img {
        width: rem(26px);
        height: auto;
      }
    }
  }

  .btn-flip {
    position: absolute;
    bottom: rem(-28px);
    background-color: $blue;
    width: 100%;
    display: none;
    border-radius: 0 0 rem(8px 8px);
    text-align: center;
    padding: rem(8px) 0;
    border: 0;
    box-shadow: none;
    font-size: rem(10px);
    line-height: normal;
    cursor: default;

    &:focus,
    &:not(:focus):not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      background-color: $blue;
      box-shadow: none;
    }

    @include media-breakpoint-up(sm) {
      bottom: rem(-27px);
      font-size: rem(16px);
    }
  }

  &:after {
    content: '';
    display: block;
    width: rem(240px);
    padding-top: (160 / 240) * 100%;
  }
}

.blue-card {
  background-image: url(../../images/gainon/rewards-blue.svg);

  &:after {
    background-image: url(../../images/gainon/rewards-blue.svg);
  }
}

.purple-card {
  background-image: url(../../images/gainon/rewards-purple.svg);

  &:after {
    background-image: url(../../images/gainon/rewards-purple.svg);
  }

  .btn-flip {
    cursor: default;
    background-color: #dc9ff2;
    &:focus,
    &:not(:focus):not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      background-color: #dc9ff2;
    }
  }
}

.red-card {
  background-image: url(../../images/gainon/rewards-red.svg);

  &:after {
    background-image: url(../../images/gainon/rewards-red.svg);
  }

  .btn-flip {
    cursor: default;
    background-color: #df5c87;
    &:focus,
    &:not(:focus):not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      background-color: #df5c87;
    }
  }
}

.black-card {
  background-image: url(../../images/gainon/rewards-black.svg);

  &:after {
    background-image: url(../../images/gainon/rewards-black.svg);
  }

  .btn-flip {
    cursor: default;
    background-color: #4a4a4a;
    &:focus,
    &:not(:focus):not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      background-color: #4a4a4a;
    }
  }
}

.white-card {
  background-image: url(../../images/gainon/rewards-plaincard.svg);
  width: rem(241px);

  &:after {
    background-image: url(../../images/gainon/rewards-plaincard.svg);
    padding-top: (160 / 239) * 100%;
  }

  .content {
    position: absolute;
    top: rem(10px);
    left: rem(15px);
    bottom: rem(10px);
    right: rem(15px);

    .term-condition-txt {
      cursor: pointer;
      color: $blue;
      font-size: rem(16px);
    }

    .rewards-heading {
      color: $gray-dk;
      font-weight: 800;
      line-height: rem(18px);
      font-size: x-large;
    }

    svg {
      width: rem(20px);
      height: rem(20px);
    }

    .company-logo {
      height: rem(30px);
      width: rem(30px);
      border-radius: rem(2px);
      background-color: $white;
      padding: rem(2px);

      img {
        width: rem(26px);
        height: auto;
      }
    }

    .btn-primary {
      min-width: unset;
      width: 100%;
    }
  }
}

.plain-card {
  background-image: url(../../images/gainon/rewards-white.svg);
  width: rem(241px);

  &:after {
    background-image: url(../../images/gainon/rewards-white.svg);
    padding-top: (160 / 239) * 100%;
  }

  .content {
    position: absolute;
    top: rem(64px);
    left: rem(16px);
    bottom: rem(10px);
    right: rem(16px);

    .txt {
      font-size: rem(12px);
      line-height: rem(15px);
      color: rgba($gray-dk, 0.5);
    }

    // .discount-txt {
    //   font-weight: 600;
    //   font-size: rem(10px);
    //   line-height: rem(13px);
    //   color: $gray-dk;
    //   margin: rem(8px) 0 rem(4px) 0;
    //   @include media-breakpoint-up(sm) {
    //     font-size: rem(14px);
    //     font-weight: 800;
    //     line-height: rem(18px);
    //     margin: rem(18px) 0 rem(12px) 0;
    //   }
    // }
    .box-copy-code {
      color: $gray-dk;
      border-radius: rem(4px);
      border: 1.3px dashed $blue;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: none;
      word-break: break-all;
      @media (min-width: 400px) {
        padding: rem(4px);
      }
      @include media-breakpoint-up(md) {
        padding: rem(10px 8px);
      }
      &.highlight {
        border-color: $red;
        color: $red;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
      }
      .icon-copy {
        color: $blue;
      }
    }
  }
}
