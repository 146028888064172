.brand-coupon {
    width: 1000px;
}

.no-margin{
    margin:0 !important;
}

.no-padding{
    padding:0 !important;
}

.add-coupon-main{
    margin: 20px !important;
    .main-row{
        padding:0 !important;
        margin-bottom: 20px;
        .onoffswitch-switch{
            width: 25px !important;
            height: 25px !important;
        }
    }
}
@media (max-width: 1500px) {
  .brand-coupon {
    width: 1000px;
  }
}

