.subscribe-modal{
    .modal-dialog{
        max-width: 900px;
        .subscribe-banner{
            background-image: url('../../img/subscribe_banner.png');
            width: 100%;
            height: 500px;
            background-size: 100% 100%;
            position: relative;
            .subscribe-input-box{
                position: absolute;
                width: 450px;
                display: flex;
                bottom: 30px;
                right: 35px;
                .form-group{
                    width: 70%;
                }
                .button-box{
                    margin-left: 10px;
                }
            }
        }
        .modal-body{
            padding: 0 !important;
        }
    }
}

@media (max-width:620px){
    .subscribe-modal{
        .modal-dialog{
            max-width: 700px;
            .subscribe-banner{
                background-image: url('../../img/subscribe_mobile_banner.png');
                height: 550px;
                background-size: 100% 65%;
                background-repeat: no-repeat;
                .subscribe-input-box{
                    bottom: 50px;
                    right: 0px;
                    flex-wrap: wrap;
                    padding: 0px 20px;
                    width: 100%;
                    .form-group{
                        width: 100%;
                    }
                    .button-box{
                        margin-left: 0px;
                        width: 100%;
                    }
                }
            }
            .modal-body{
                padding: 0 !important;
            }
        }
    }
}