@import "../core";

.about-info {
    padding: rem(188px 0 100px);
    
    @include media-breakpoint-down(xs) {
        padding: rem(52px 0 30px);
    }
   }
   .page-about {
    background-color: $white;
    .about-title {
        font-size: rem(74px);
        font-weight: 800;
        line-height: rem(93px);
        margin-bottom: rem(15px);

        span {
            color: $red;
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(36px);
          text-align: center;
          line-height: rem(45px);
          margin-bottom: rem(5px);
        }
    }
   
  

    h3 {
        font-size: rem(30px);
        line-height: rem(38px);
        font-weight: normal;
        opacity: 0.6;
        margin-bottom: rem(40px);

        @include media-breakpoint-down(xs) {
           font-size: rem(14px);
           line-height: rem(18px);
           text-align: center;
           margin-bottom: rem(32px);
        }
    }

    p {
        font-size: rem(16px);
        line-height: rem(24px);
        margin-bottom: rem(20px);
        font-weight: 600;
        @include media-breakpoint-down(xs) { 
            font-size: rem(14px);
            line-height: rem(24px);
            margin-bottom: rem(28px);
        }
    }
}

.image-wrap {
    height: 100%;
    >img {
        width: rem(360px);
        height: auto;
        @include media-breakpoint-down(xs) {
            width: rem(200px);
        }
    }
}

.gainon-mob-image {
    @include media-breakpoint-down(xs) {
        width: rem(200px);
        height: auto;
        margin: 0 auto;
    }
}

.go-about {
    padding: rem(100px 0);

    @include media-breakpoint-down(xs) {
        padding: rem(30px 0);
    }
    p {
        font-size: rem(16px);
        line-height: rem(24px);
        @include media-breakpoint-down(xs) { 
            font-size: rem(14px);
            line-height: rem(24px);
        }
    }

    .row {
        @include media-breakpoint-down(xs) {
           flex-direction: column;
        }
    }

    .about-image {
        width: rem(268px);
        height: 100%;
        @include media-breakpoint-down(xs) {
            width: auto;
            height: rem(120px);
            margin-bottom: rem(24px);
        }
    }
    &.-values {
        padding-top: rem(96px);
    }

    h2 {
        font-size: rem(40px);
        font-weight: 800;
        line-height: rem(50px);
        margin-bottom: rem(32px);
        position: relative;

        @include media-breakpoint-down(xs) {
        font-size: rem(18px);
        line-height: rem(23px);
        }

        &::before {
            content: '';
            height: rem(6px);
            width: rem(43px);
            background-color: $gray-dk;
            left: 0;
            right: 0;
            bottom: rem(-8px);
            position: absolute;

            @include media-breakpoint-down(xs) {
                width: rem(23px);
                height: rem(4px);
            }
        }
    }
}

.left-sec {
    p {
        width: 65%;
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }
    @include media-breakpoint-down(xs) {
       order: 2;
    }
}

.right-sec {
    @include media-breakpoint-down(xs) {
       order: 1;
    }
}

.value-icon {
    width: auto;
    height: rem(80px);
    margin-bottom: rem(40px);
    @include media-breakpoint-down(xs) {
        height: rem(60px);
        margin-bottom: rem(28px);
    }
}
.values-content {
    padding: rem(0 30px);

    @include media-breakpoint-down(xs) {
        padding: rem(0 0 30px);
    }
    h4 {
        font-size: rem(24px);
        line-height: rem(30px);
        font-weight: 800;
        margin-bottom: rem(12px);
        @include media-breakpoint-down(xs) {
            font-size: rem(16px);
            line-height: rem(20px);
            margin-bottom: rem(5px);
        }
    }
    p {
        font-size: rem(14px);
        line-height: rem(21px);
        font-weight: normal;
    }
}

.section-thankyou {
    position: relative;
    height: auto;
    padding-bottom: rem(84px);
    margin-top: rem(80px);
    padding-top: rem(20px);
    @include media-breakpoint-up(sm) {
        padding-bottom: rem(100px);
        padding-top: rem(36px);
        margin-top: 0;
    }
    @include media-breakpoint-up(md) {
        padding-bottom: rem(116px);
        padding-top: rem(68px);
        margin-top: 0;
        margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
        padding-top: rem(180px);
        margin-bottom: 140px;
    }
    .bgmask{
        position: absolute;
        height: auto;
        width: 100vw;
        left: 0;
        top: -16vw;

        @include media-breakpoint-up(sm) {
            height: rem(400px);
            top: -8vw;
        }
        @include media-breakpoint-up(md) {
            height: rem(350px);
            top: -2vw;
        }
        @include media-breakpoint-up(lg) {
            height: rem(490px);
            top: 2vw;
        }
    }

    .fav-icon {
        position: relative; 
        width: rem(53px);
        height: auto;
        margin-bottom: rem(28px);
        @include media-breakpoint-up(md) {
            width: rem(80px);
        }
    }

    h4 {
        position: relative; 
        font-size: rem(16px);
        line-height: rem(24px);
        color: $white;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
            font-size: rem(32px);
            line-height: rem(44px) ;
        }
    }
    .thankyou-content {
        text-align: center;
    }
}

.value-section {
    h2 {
        margin-bottom: rem(116px);
        &::before {
        bottom: rem(-16px);
        left: rem(50%);
        }
    }
    @include media-breakpoint-down(xs) {
        padding: rem(50px 0 30px);
    }
    .carousel-indicators {
        li{
           width: rem(6px);
           height: rem(6px);
           background-color: $gray-md;
           border-radius: 50%;
           -webkit-border-radius: 50%;
           -moz-border-radius: 50%;
           -ms-border-radius: 50%;
           -o-border-radius: 50%;
           &.active {
               background-color: $blue;
           }
        }
    }
}