@import "../core";

.section-banner {
  // border: 1px solid blue;
  padding: rem(130px) 0 rem(50px) 0;
  @include media-breakpoint-down(sm) {
    height: rem(458px);
  }

  @extend .position-relative;

  @include media-breakpoint-down(sm) {
    padding-top: rem(58px);
    padding-bottom: 0;
  }

  .brands-group {
    // width: rem(608px);
    height: auto;
    position: relative;
    // @extend .position-absolute;
    top: 0;
    z-index: 9;
    width: 80%;
    margin: 0 auto;
    display: block;
    right: 0;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: rem(-30%);
      width: 50%;
      right: rem(30px);
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    // @include media-breakpoint-down(sm) {
    //   width: rem(227px);
    //   height: auto;
    //   bottom: rem(-200px);
    //   left: rem(50%);
    //   top: unset;
    //   transform: translateX(-50%);
    // }
  }
}

.heading {
  > h2 {
    font-size: rem(30px);
    @extend .text-capitalize, .mb-3;
    color: rgba($gray-dk, 0.6);
    text-align: center;
    font-size: rem(14px);
    // line-height: rem(38px);

    // @include media-breakpoint-down(sm) {
    //   text-align: center;
    //   font-size: rem(14px);
    //   line-height: rem(18px);
    //   margin-bottom: rem(12px);
    // }
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
      font-size: rem(26px);
    }
    @include media-breakpoint-up(lg) {
      text-align: left;
      font-size: rem(26px);
    }
    @include media-breakpoint-up(xl) {
    }
  }

  > h1 {
    color: $gray-dk;
    font-weight: 800;
    text-align: center;
    font-size: rem(36px);
    margin-bottom: rem(40px);

    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
      font-size: rem(74px);
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      text-align: left;
      font-size: rem(60px);
    }
    @include media-breakpoint-up(xl) {
      font-size: rem(74px);
    }

    > span {
      color: $red;
    }
  }
}

.section-gainon {
  .title {
    margin-bottom: rem(30px);
    font-size: rem(24px);
    font-weight: 800;
    line-height: rem(30px);
    @extend .position-relative;

    @include media-breakpoint-down(sm) {
      font-size: rem(18px);
      line-height: rem(23px);
      text-align: center;
    }

    &:after {
      @extend .position-absolute;
      content: "";
      bottom: rem(-6px);
      left: 0;
      height: rem(4px);
      width: rem(23px);

      @include media-breakpoint-down(sm) {
        left: 50%;
      }
    }
  }
}

.input-group-search {
  border: 1px solid #e2e2e2;
  @extend .rounded, .shadow-sm;
  background-color: $white;
  z-index: 999;
  margin: rem(23px) 0 rem(20px);
  width: 100%;

  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {
    // margin: rem(23px) 0 rem(20px);
  }
  @include media-breakpoint-up(lg) {
    margin: rem(23px) 0 rem(120px);
    width: rem(400px);
  }
  @include media-breakpoint-up(xl) {
    margin: rem(23px) 0 rem(260px);
    width: rem(480px);
  }

  > .input-group-prepend,
  .input-group-append {
    > .input-group-text {
      @extend .border-0;
      padding: rem(9px 10px);

      @include media-breakpoint-up(sm) {
      }
      @include media-breakpoint-up(md) {
        padding: rem(14px 12px);
      }
      @include media-breakpoint-up(lg) {
      }
      @include media-breakpoint-up(xl) {
      }
    }
  }
  .home-page-icon {
    font-size: rem(24px);
    &.icon-search {
      color: #989998;
    }
    &.icon-arrow-right {
      color: $red;
    }
  }
  .input-box-field {
    flex: 72%;
    @include media-breakpoint-up(md) {
      flex: 1;
    }
  }
  
   .form-control {
    padding: rem(10px 12px);
    font-size: rem(14px);
    height: rem(42px);
    @extend .border-0;

    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
      padding: rem(16px 12px);
      height: rem(52px);
      font-size: rem(18px);
    }
    @include media-breakpoint-up(lg) {
    }
    @include media-breakpoint-up(xl) {
    }
  }

  .dropdown {
    position: absolute;
    top: rem(57px);
    width: 100%;
    left: 0;
    border: 1px solid #e2e2e2;
    border-radius: 0 0 rem(4px 4px);
  }

  .dropdown-item {
    background-color: $white;

    &:last-child {
      border-radius: 0 0 rem(4px 4px);
    }

    a {
      text-decoration: none;
      @extend .d-flex, .align-items-center;
    }

    img {
      height: auto;
      width: rem(50px);
    }

    span {
      font-size: rem(18px);
      @extend .d-block, .text-truncate, .font-weight-bold;
      color: $gray-dk;
    }
  }
}
.section-how-it-works,
.section-favourite-brand {
  .bgmask {
    position: absolute;
    height: auto;
  }
}
.section-how-it-works {
  position: relative;
  height: auto;
  padding-bottom: rem(126px);

  @include media-breakpoint-up(md) {
    padding-bottom: rem(50px);
    padding-bottom: rem(0);
  }
  @include media-breakpoint-up(lg) {
    height: 45vw;
  }

  .bgmask {
    width: 100vw;
    left: 0;
    top: 4vw;

    @include media-breakpoint-up(sm) {
      width: 100vw;
      left: 0;
      top: 4vw;
    }
    @include media-breakpoint-up(md) {
      width: 100vw;
      left: 0;
      top: -28vw;
      height: rem(1085px);
    }
    @include media-breakpoint-up(lg) {
      width: 120%;
      left: -20%;
      top: -24vw;
      height: auto;
    }
    @include media-breakpoint-up(xl) {
      width: 100%;
      left: 0;
      top: -24vw;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-top: rem(100px);
  }

  .title {
    color: $white;

    &:after {
      background-color: $white;
    }
  }

  .select-brand-img {
    @extend .w-100, .h-auto;
    position: relative;
    top: rem(-40px);
  }
}

.card-how-it-works {
  background-color: transparent;
  @extend .border-0;
  color: $white;
  cursor: pointer;

  &.active {
    border-radius: rem(8px);
    background-color: rgba($white, 0.1);

    > ul {
      list-style-type: disc;
    }
  }

  ul {
    padding-left: rem(18px);
    margin-bottom: 0;
    list-style-type: circle;
  }

  li {
    font-size: rem(18px);
    @extend .font-weight-bold;
    line-height: rem(27px);
  }

  > p {
    font-size: rem(14px);
    margin-bottom: 0;
    line-height: rem(21px);
    padding-left: rem(18px);
  }
}

.section-most-popular {
  padding: rem(20px) 0;
  @include media-breakpoint-up(sm) {
    padding: rem(20px) 0;
  }
  @include media-breakpoint-up(md) {
    padding: rem(180px 0 80px);
  }
  @include media-breakpoint-up(xl) {
    padding: rem(80px 0);
  }

  a {
    font-size: rem(14px);
    @extend .font-weight-bold, .text-uppercase, .text-decoration-none;
    line-height: rem(18px);
  }

  .brand-box {
    min-height: rem(208px);
    min-width: rem(208px);
    @extend .px-4;
    padding: rem(32px 28px);

    @include media-breakpoint-down(sm) {
      min-height: rem(160px);
      min-width: rem(160px);
    }

    &:hover {
      .brand-logo {
        width: 20%;
      }

      > strong {
        margin-bottom: rem(36px);
      }

      p {
        margin-bottom: rem(50px);
      }
    }
  }
}

.section-favourite-brand {
  position: relative;
  padding: rem(120px 0 100px);

  @include media-breakpoint-up(md) {
    padding: rem(120px 0 60px);
  }
  @include media-breakpoint-up(lg) {
    padding: rem(120px 0 160px);
  }
  @include media-breakpoint-up(xl) {
    padding: rem(220px 0 180px);
  }

  .bgmask {
    width: 100vw;
    left: 0;
    top: 4vw;
    height: rem(476px);

    @include media-breakpoint-up(md) {
      height: rem(600px);
      top: 2vw;
    }
    @include media-breakpoint-up(lg) {
      top: -2vw;
      height: rem(750px);
    }
    @include media-breakpoint-up(xl) {
      width: 100%;
      height: rem(901px);
      left: 0;
      top: 0;
    }
  }
  a {
    position: relative;
    font-size: rem(14px);
    @extend .font-weight-bold, .text-uppercase, .text-decoration-none;
    line-height: rem(18px);
  }
  .title {
    text-align: left;
  }
}

.section-customer {
  padding: rem(175px) 0 rem(80px);
  @include media-breakpoint-down(sm) {
    padding: rem(40px) 0;
  }

  .title {
    margin-bottom: rem(70px);
    @include media-breakpoint-down(sm) {
      margin-bottom: rem(32px);
    }

    &:after {
      background-color: $gray-dk;
      left: 50%;
    }
  }
  .carousel-inner {
    width: rem(571px);
    @extend .mx-auto;

    @include media-breakpoint-down(sm) {
      width: rem(280px);
    }
  }

  .carousel-indicators > li {
    background-color: rgba($gray-dk, 0.2);
    @include media-breakpoint-down(sm) {
      width: rem(6px);
      height: rem(6px);
    }

    &.active {
      background-color: $blue;
    }
  }

  .carousel-item {
    > p {
      color: $gray-dk;
      font-size: rem(18px);
      margin-bottom: rem(48px);
      @extend .font-weight-bold;
      line-height: rem(27px);

      @include media-breakpoint-down(sm) {
        font-size: rem(14px);
        line-height: rem(18px);
      }
    }
  }
}

.box-customer-say {
  @extend .position-relative;
  padding: rem(23px 47px);
  margin-bottom: rem(32px);
  @include media-breakpoint-down(sm) {
    padding: rem(16px);
    margin-bottom: rem(15px);
  }

  &::before,
  &::after {
    @extend .position-absolute;
    content: "";
    height: rem(35px);
    width: rem(47px);

    @include media-breakpoint-down(sm) {
      height: rem(18px);
      width: rem(24px);
      opacity: 0.34;
    }
  }

  &:before {
    background: url(../../../assets/images/gainon/quote-left.svg) no-repeat;
    top: 0;
    left: 0;
  }
  &:after {
    background: url(../../../assets/images/gainon/quote-right.svg) no-repeat;
    bottom: 0;
    right: 0;
  }
  > p {
    color: $gray-dk;
    font-size: rem(18px);
    margin-bottom: 0;
    line-height: rem(27px);
    @include media-breakpoint-down(sm) {
      font-size: rem(14px);
      line-height: rem(24px);
      font-weight: normal;
    }
  }
}

.box-avtar {
  height: rem(48px);
  width: rem(48px);
  border-radius: 50%;
  @extend .d-flex,
    .align-items-center,
    .justify-content-center,
    .mx-auto,
    .overflow-hidden;

  @include media-breakpoint-down(sm) {
    height: rem(36px);
    width: rem(36px);
  }

  img {
    width: rem(48px);
    @extend .h-auto;
  }
}

.carousel-how-it-works {
  .carousel-item {
    > h6,
    > p {
      color: $white;
      line-height: rem(18px);
    }
    > h6 {
      font-size: rem(14px);
      @extend .font-weight-bold;
      height: rem(36px);
    }
    > p {
      font-size: rem(12px);
      @extend .mb-4;
      height: rem(55px);
    }
  }

  .carousel-indicators > li {
    border: 2px solid $white;
    background-color: transparent;

    &.active {
      background-color: $white;
    }
  }
}

// .brand-box-home{
//   position: relative;
//   height: rem(207px);
// }
// .brand-box1 {
//   position: relative;
//   height: rem(207px);
//   .brand-box1-container {
//   position: absolute;
//   border: 1px solid #E3E3E3;
//   background-color: $white;
//   border-radius: 8px;
//   min-height: rem(207px);
//   min-width: rem(208px);
//   padding: rem(32px 40px);
//   left: 0;
//   right: 0;
//   bottom: 0;
//   top: 0;
//   z-index: 0;
//   transition: all 0.5s;
//   -webkit-transition: all 0.5s;
//   -moz-transition: all 0.5s;
//   -ms-transition: all 0.5s;
//   -o-transition: all 0.5s;
//       &:hover {
//         top: rem(-15px);
//         bottom: rem(-15px);
//         left: rem(-25px);
//         right: rem(-25px);
//         z-index: 9;
//     }
//   }
// }

.section-banner,
.section-gainon {
  background-color: $white;
}

.section-gainon {
  .brand-home-wrap,
  .brand-box-wrap {
    position: relative;

    // display: block;
    overflow-y: auto;
    // // min-width: 210px;
    // // max-width: 240px;
    display: grid;
    grid-gap: rem(16px);
    grid-template-columns: repeat(auto-fill, minmax(210px, 0.5fr));
    // // grid-template-columns: repeat(auto-fill,minmax(160px,1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(210px, 0.5fr);
    // overflow-x: auto;
    margin-bottom: rem(20px);
    @include media-breakpoint-up(sm) {
      margin-bottom: rem(20px);
    }
    @include media-breakpoint-up(md) {
      margin-bottom: rem(20px);
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(xl) {
      overflow-y: inherit;
      margin-bottom: 0;
    }
  }
}
.brand-box-home,
.voucher-box-home,
.brand-box-home-sm,
.voucher-box-home-sm {
  position: relative;
  z-index: 1;
  padding: rem(22px 18px);
  box-shadow: none;
  transform: scale(1);

  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;

  &:after {
    content: "";
    display: block;
    // padding-bottom: 100%;x
  }

  .brand-logo {
    width: 60%;
    @include media-breakpoint-down(sm) {
      width: 40%;
      margin-bottom: rem(20px);
    }
  }
}

.brand-box-home,
.voucher-box-home {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    &:hover {
      transform: scale(1) !important;
      -webkit-transform: scale(1) !important;
      -moz-transform: scale(1) !important;
      -ms-transform: scale(1) !important;
      -o-transform: scale(1) !important;
    }
  }
  &:hover {
    padding: rem(18px);
    z-index: 2;
    transform: scale(1.2);
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
    transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);

    .brand-logo {
      width: 40%;
      margin-bottom: rem(15px);
    }
    > strong {
      font-size: rem(16px);
    }
    .box-content {
      transform: scale(0.769);
      transition: transform 0.3s;
      -webkit-transition: transform 0.3s;
      -moz-transition: transform 0.3s;
      -ms-transition: transform 0.3s;
      -o-transition: transform 0.3s;

      .button-wrap {
        visibility: visible;
        height: auto;
        opacity: 1;
      }
    }
  }

  .box-content {
    .button-wrap {
      visibility: hidden;
      height: 0;
      opacity: 0;
    }
  }
}
.brand-box-home,
.brand-box-home-sm {
  border: 1px solid #e3e3e3;
  background-color: $white;
  border-radius: rem(8px);

  .box-content {
    p {
      font-size: rem(16px);
      line-height: rem(20px);
      opacity: 0.65;
      font-weight: normal;
      margin: 0;
      @include media-breakpoint-down(md) {
        font-size: rem(14px);
        line-height: rem(18px);
      }
    }
  }

  // &:after {
  //   content: "";
  //   display: block;
  //   padding-bottom: 100%;
  // }
}
.brand-box-home {
  .brand-logo {
    margin-bottom: rem(25px);
  }

  &:hover {
    .box-content {
      p {
        margin-bottom: rem(10px);
      }
    }
  }
}
.voucher-box-home,
.voucher-box-home-sm {
  background: $white;
  border-style: solid;
  border-width: 15px;
  border-image: url("../../images/gainon/dots-2.svg") 15 round;
  font-size: rem(14px);
  border-image-outset: rem(5px);
  padding: rem(12px 18px);
  margin: rem(0 4px);

  .box-content {
    p {
      font-size: rem(14px);
      line-height: rem(18px);
      font-weight: normal;
      opacity: 0.6;
      margin: 0;
      height: auto;
      visibility: visible;
    }
    strong {
      font-weight: normal !important;
      opacity: 0.65;
      @include media-breakpoint-up(lg) {
        font-weight: bold !important;
        opacity: 1;
      }
    }
  }

  // &:after {
  //   content: "";
  //   display: block;
  //   padding-bottom: 100%;
  // }
  // p{
  //   margin: 0;
  //   color: rgba($gray-dk, .5);
  // }
  // .content {
  //   background-color: white;
  //   position: absolute;
  //   padding: rem(4px);
  //   min-width: 100%;
  //   min-height: 100%;
  //   @extend .d-flex, .flex-column, .align-items-center,
  // .justify-content-center, .text-center;
  // }
  // .brand-logo{
  //   width: 40%;
  //   margin-bottom: rem(18px);
  // }
}

.voucher-box-home {
  .brand-logo {
    margin-bottom: rem(5px);
  }
  &:hover {
    .box-content {
      p {
        opacity: 0;
        height: 0;
        visibility: hidden;
      }
      strong {
        margin-bottom: rem(32px);
      }
    }
  }
}

.section-gainon {
  .box-content {
    // background-color: yellowgreen;
    transform: scale(1);
    @extend .d-flex,
      .flex-column,
      .align-items-center,
      .justify-content-center,
      .text-center;

    transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    -ms-transition: transform 0.3s;
    -o-transition: transform 0.3s;

    > strong {
      font-size: rem(18px);
      line-height: rem(23px);
      font-weight: bold;
      margin-bottom: rem(16px);

      @include media-breakpoint-down(md) {
        font-size: rem(16px);
        line-height: rem(20px);
        margin-bottom: rem(10px);
      }
    }
  }
}
.section-most-popular,
.section-favourite-brand {
  .title {
    &:after {
      background-color: $gray-dk;
      @include media-breakpoint-down(sm) {
        left: 0;
      }
    }
  }
}
.brand-box-wrap {
  > .voucher-box-content {
    padding: rem(10px);
    .voucher-box-home-sm {
      border-width: 18px;
      border-image: url(/static/media/dots-2.45774542.svg) 18 round;
      border-image-outset: rem(10px);
      margin: 0;
      padding: rem(18px 22px);
    }
  }
}
