.form-group label,
.form-group input {
    font-size: smaller;

}

.form-group label {
    color: #000;
}

.btn-vbc {
    min-width: 60px !important;
}

.model-btn {
    display: block;
    padding: 8px;
    width: 100%;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .1);
    font-weight: 500;
}

@media only screen and (min-width: 768px) {
    .vendor-brand-configurations {
        width: 700px !important;
        margin-left: -180px !important;

    }
}

@media only screen and (min-width: 992px) {
    .vendor-brand-configurations {
        width: 800px !important;
        margin-left: -200px !important;

    }
}

@media only screen and (min-width: 1200px) {
    .vendor-brand-configurations {
        width: 900px !important;
        margin-left: -40px !important;

    }
}

// @media (max-width: 1500px) {
//     .vendor-brand-configurations {
//         max-width: 900px !important;
//     }
// }

// @media (max-width: 1300px) {
//     .vendor-brand-configurations {
//         max-width: 800px !important;
//     }
// }