/*******************************************************
  Style Color
********************************************************/
/*   1.HD devices Desktops     	  ( Devices > 1450px)
 *   1.Large devices Desktops     ( Devices >1200px)
 *   2.Medium devices Desktops     (992px > Devices > 1199px)
 *   3.Small devices Tablets      (991px > Devices > 768px)
 *   4.Extra small devices Phones ( Devices < 767px)
 */
/* 1.HD devices Desktops  
================================================== */
@media (min-width: 1450px) {
  @-moz-document url-prefix() {
    #thumb-slider .owl2-carousel .owl2-stage-outer {
      margin: 0 -2px; } } }
/* 2.Large devices Desktops 
================================================== */
@media (max-width: 991px) {
  .navbar-header .navbar-toggle {
    left: 10px; }

   .sidebar-megamenu ul.megamenu > li {
    border-left: 0;
    border-right: 0; }

  .megamenu-wrapper {
    max-height: 100%; }

  .rtl .megamenu-wrapper #remove-megamenu {
    text-align: left; }
  .rtl .responsive ul.megamenu > li {
    text-align: right; }
  .rtl .header-bottom-menu .responsive ul.megamenu > li.click:before,
  .rtl .header-bottom-menu .responsive ul.megamenu > li.hover:before,
  .rtl .header-bottom-menu .responsive ul.megamenu > li.active .close-menu {
    padding-top: 3px !important; }
  .rtl .sidebar-menu-left .vertical-menu .vertical ul.megamenu .sub-menu {
    right: 0 !important; } }
/* 3.Medium devices Desktops 
================================================== */
@media (min-width: 992px) and (max-width: 1199px) {
  /*--------------------HEADER ----------------*/
  

   .sidebar-megamenu ul.megamenu > li > a {
    padding: 0 10px; }

  .common-home .sidebar-menu .box-category-menu > ul {
    transform: scale(0); }
  .common-home .sidebar-menu .box-category-menu:hover > ul {
    transform: scale(1); }

  .sidebar-menu .vertical-menu .cat-title {
    font-size: 13px; }

   .sidebar-megamenu .horizontal .item-style2 .sub-menu .content > .row > .col-sm-8 {
    width: 58%; }
   .sidebar-megamenu .horizontal .item-style2 .sub-menu .content > .row > .col-sm-4 {
    width: 42%; }
     .sidebar-megamenu .horizontal .item-style2 .sub-menu .content > .row > .col-sm-4 .list-product .product-thumb {
      padding: 10px 0; }

  /*------------CUSTOM HTML ---------------*/
  .banner-wraps .m-banner img {
    width: 100%; }

  .accordion-section .accordion-section-title {
    line-height: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px; }

  .banner-mid-1 > ul > li {
    width: 25%; }

  /*-----------PRODUCT LISTING ----------------*/
  .products-list .product-layout .button-group .wishlist, .products-list .product-layout .button-group .compare {
    width: 30px;
    padding: 6px; }
  .products-list .product-layout .button-group .addToCart {
    padding: 7px 5px; }

  div.products-list.list .product-layout .product-item-container .button-group button.addToCart {
    background-position: center; }

  .short-by-show .form-group {
    margin-bottom: 4px; }

  .products-list .product-layout .left-block .countdown_box {
    width: auto;
    right: -10px;
    left: -10px; }

  /*------Footer ------*/
  .news-letter .block_content .input-box input {
    width: 200px; }

  .social_block ul.social-block li {
    margin: 0 5px; }

  ul.payment-method li {
    margin-bottom: 6px; }

  .module.cus-style-supper-cate .main-left .hot-category .category-wrap-cat .title-imageslider {
    font-size: 14px; }
  .module.cus-style-supper-cate .main-left .hot-category .category-wrap-cat .slider .cat_slider_inner .item .cat_slider_title a {
    font-size: 12px; }
  .module.cus-style-supper-cate .main-right .spcat-items-container.products-list.grid .product-layout .product-item-container .button-group button {
    opacity: 1; }
  .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item .product-item-container {
    padding: 7px 10px 0; }
  .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item:nth-child(1) .image {
    margin: 0 0 -12px; }

  .sidebar-menu-left .vertical-menu .vertical-wrapper {
    display: none; }
  .sidebar-menu-left:hover .vertical-menu .vertical-wrapper {
    display: block; }

  .banner-mid-1 {
    max-height: 142px; }

  .module.cus-style-supper-cate .main-right .spcat-items-container.products-list.grid .product-layout .product-item-container .right-block {
    padding: 0; }

  #so-slideshow {
    width: 100%;
    margin: 0 0 40px; }

  .module div.products-list.grid .product-layout .product-item-container .left-block .quickview,
  .module div.products-list.grid .product-layout .product-item-container .button-group button {
    opacity: 1; }

  .about-us .owl2-controls .owl2-nav div {
    top: 23%; }

  .best-seller .so-extraslider .extraslider-inner .item-wrap-inner {
    margin: 11px 0 0; }

  .rtl {
    /*------------------ BREADCRUM ---------------*/ }
    .rtl .accordion-section .accordion-section-title {
      padding-right: 47px;
      padding-left: 5px; }
    .rtl .blog-listitem .blog-item .itemBlogContent .article-sub-title .article-date i {
      margin-right: 0;
      margin-left: 5px; }
    .rtl .blog-listitem .blog-item .itemBlogContent .blog-meta {
      float: right; }
      .rtl .blog-listitem .blog-item .itemBlogContent .blog-meta > span.comment_count {
        float: left; }
      .rtl .blog-listitem .blog-item .itemBlogContent .blog-meta span.author {
        padding-right: 0; }
    .rtl .products-list .product-layout .button-group .addToCart {
      font-size: 9px; }
    .rtl div.products-list.list .product-layout .product-item-container .button-group button.addToCart {
      background-position: center; }
    .rtl .left-content-product .row {
      margin: 0; }
    .rtl .box-footer:last-child {
      padding-left: 0; }
    .rtl ul.payment-method li {
      margin-left: 2px;
      margin-right: 0; } }
@media (max-width: 991px) {
  .rtl #menu .navbar-header .menu-btn {
    float: right; } }
/* 4.Small devices Tablets
================================================== */
@media (min-width: 768px) and (max-width: 991px) {
  /*-------HEADER-------*/
  .header-bottom-menu .responsive ul.megamenu > li.click:before, .header-bottom-menu .responsive ul.megamenu > li.hover:before,
  .header-bottom-menu .responsive ul.megamenu > li.active .close-menu {
    padding-top: 0; }

  .responsive.sidebar-megamenu .navbar-default .megamenu-wrapper ul.megamenu > li.active:before, .responsive.sidebar-megamenu .navbar-default .vertical-wrapper ul.megamenu > li.active:before {
    display: none; }

  .responsive.sidebar-megamenu .navbar-default .megamenu-wrapper ul.megamenu .sub-menu .content .row, .responsive.sidebar-megamenu .navbar-default .vertical-wrapper ul.megamenu .sub-menu .content .row {
    margin: 0; }
    .responsive.sidebar-megamenu .navbar-default .megamenu-wrapper ul.megamenu .sub-menu .content .row [class^=col], .responsive.sidebar-megamenu .navbar-default .vertical-wrapper ul.megamenu .sub-menu .content .row [class^=col] {
      padding: 0; }

  .responsive ul.megamenu .sub-menu .content .row > div {
    border-top: none;
    margin: 0 0 10px; }

  .sidebar-menu-left .vertical-menu .vertical ul.megamenu > li.item-style3 .sub-menu .content {
    padding: 6px; }

  .sidebar-menu-left .vertical-menu ul.megamenu {
    width: auto;
    position: relative;
    background: none;
    display: block; }

   .sidebar-megamenu .vertical-menu .vertical-wrapper ul.megamenu > li:hover {
    background: none !important; }

  .sidebar-menu-left .vertical-menu .vertical-wrapper ul li > a {
    line-height: 38px; }
    .sidebar-menu-left .vertical-menu .vertical-wrapper ul li > a strong {
      color: #fff; }

  .responsive.sidebar-megamenu .navbar-default  .sidebar-megamenu-active #remove-megamenu {
    font-size: 20px;
    color: #fff; }
  .responsive.sidebar-megamenu .navbar-default .vertical-wrapper, .responsive.sidebar-megamenu .navbar-default .megamenu-wrapper {
    border: none;
    border-radius: 0;
    background: #333; }
  .responsive.sidebar-megamenu .navbar-default div[class^=col-] {
    width: 100%; }
  .responsive.sidebar-megamenu .navbar-default ul.megamenu > li {
    padding: 3px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.click::before, .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.hover::before {
    padding: 11px 15px !important;
    font-size: 14px;
    background: #f8ab16;
    color: #fff; }
  .responsive.sidebar-megamenu .navbar-default .horizontal ul.megamenu > li.active .close-menu {
    line-height: 50px; }
  .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.active {
    padding: 0; }
    .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.active > a {
      padding: 10px !important;
      background: #222 !important;
      color: #fff; }
    .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.active .close-menu {
      padding: 0;
      width: 41px;
      line-height: 60px;
      background: #f8ab16; }
      .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.active .close-menu:before {
        color: #fff;
        font-size: 12px;
        line-height: 18px; }
  .responsive.sidebar-megamenu .navbar-default .navbar-default .horizontal .megamenu-wrapper ul.megamenu li .sub-menu .content {
    box-shadow: none;
    border: 1px solid #eee; }

  .navbar-default .vertical .navbar-toggle, .navbar-default .horizontal .navbar-toggle {
    height: 39px;
    margin: 3px 0 0;
    color: #fff;
    border-radius: 0;
    border-width: 1px;
    border-style: solid; }
    .navbar-default .vertical .navbar-toggle .icon-bar, .navbar-default .horizontal .navbar-toggle .icon-bar {
      background: #fff; }
  .navbar-default .vertical .megamenu-wrapper, .navbar-default .horizontal .megamenu-wrapper {
    background: #333; }
    .navbar-default .vertical .megamenu-wrapper ul.megamenu > li.active > a, .navbar-default .horizontal .megamenu-wrapper ul.megamenu > li.active > a {
      padding: 5px 10px; }
    .navbar-default .vertical .megamenu-wrapper ul.megamenu > li > a, .navbar-default .horizontal .megamenu-wrapper ul.megamenu > li > a {
      color: #fff;
      padding: 5px 0;
      font-size: 14px; }
      .navbar-default .vertical .megamenu-wrapper ul.megamenu > li > a strong, .navbar-default .horizontal .megamenu-wrapper ul.megamenu > li > a strong {
        color: #fff; }
      .navbar-default .vertical .megamenu-wrapper ul.megamenu > li > a:hover, .navbar-default .horizontal .megamenu-wrapper ul.megamenu > li > a:hover {
        color: #f8ab16; }
    .navbar-default .vertical .megamenu-wrapper ul.megamenu li .sub-menu .content .title-submenu, .navbar-default .horizontal .megamenu-wrapper ul.megamenu li .sub-menu .content .title-submenu {
      padding: 0;
      margin: 0;
      border: none; }
  .navbar-default .vertical .features-menu .column.layout-color a, .navbar-default .horizontal .features-menu .column.layout-color a {
    display: inline-block; }
  .navbar-default .vertical .features-menu .column a, .navbar-default .horizontal .features-menu .column a {
    margin: 0 0 30px;
    display: block; }

  .sidebar-menu-left  .sidebar-megamenu .navbar-default {
    float: left; }

  .vertical-menu:after {
    display: none; }

  #so-slideshow {
    padding: 0; }

  #sosearchpro.search-pro {
    padding-left: 15px;
    margin: 0; }

  /*------------------ BREADCRUM ---------------*/
  .shopping_cart .btn-shopping-cart .text-shopping-cart {
    margin: 13px 5px 0 10px; }
  .shopping_cart .btn-shopping-cart a.top_cart .shopcart span.handle {
    background-position: 5px 16px; }
  .shopping_cart:after {
    display: none; }

  #yt_header_right {
    padding: 0 15px; }

  .module.sohomepage-slider .owl2-item.active .sohomeslider-description .image {
    width: 25%; }

  /*----------CONTENT ---------*/
  .common-home #content .row {
    margin: 0; }

  /*--------------------MOD-------------------*/
  div.container-slider .item-sub-cat {
    background: #525252 url("../images/icon/btn-menu-mobile.html") no-repeat scroll center center;
    right: 10px;
    top: 6px;
    cursor: pointer;
    height: 27px;
    position: absolute;
    width: 35px;
    display: inline-block;
    float: right; }
    div.container-slider .item-sub-cat ul {
      background-color: #fff;
      border: 1px solid #ddd;
      display: none;
      padding: 10px 0;
      position: absolute;
      right: 0;
      top: 100%;
      width: 150px;
      z-index: 9999;
      margin: 0; }
      div.container-slider .item-sub-cat ul li {
        display: block;
        float: none;
        margin-left: 5px;
        padding: 0 5px; }
        div.container-slider .item-sub-cat ul li a {
          padding: 0;
          text-transform: capitalize;
          white-space: normal;
          border: 0; }
    div.container-slider .item-sub-cat:hover ul {
      display: block; }

  div.container-slider .categoryslider-content {
    border-color: transparent; }

  .module.cus-style-supper-cate .main-left {
    width: 27%; }
    .module.cus-style-supper-cate .main-left .hot-category .category-wrap-cat .slider {
      min-height: 252px; }
  .module.cus-style-supper-cate .main-right {
    width: 73%; }
    .module.cus-style-supper-cate .main-right .banner-pre-text {
      display: none; }
    .module.cus-style-supper-cate .main-right .spcat-items-container.products-list.grid .product-layout .product-item-container {
      min-height: 289px; }
    .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item {
      width: 33.3333333%; }
      .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item:nth-child(1) {
        position: static;
        width: 33.3333333%; }
        .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item:nth-child(1) .product-item-container {
          min-height: 289px; }
    .module.cus-style-supper-cate .main-right .spcat-items-container .ltabs-items-inner .ltabs-item {
      width: 33.3333333%; }

  .module div.products-list.grid .product-layout .product-item-container .left-block .quickview,
  .module div.products-list.grid .product-layout .product-item-container .button-group button {
    opacity: 1; }

  /*----------------------- CUSTOM HTML --------------------*/
  .accordion-section .accordion-section-title {
    line-height: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px; }

  .banner-mid-1 > ul {
    margin: -15px; }
    .banner-mid-1 > ul > li {
      width: 50%;
      padding: 0 15px;
      margin: 0 0 15px; }

  div.yt-pricing .style2.column:last-child {
    padding-right: 15px; }

  /*---------------PRODUCT LISTING -------------*/
  .products-list .product-layout .button-group .wishlist, .products-list .product-layout .button-group .compare {
    width: 30px;
    padding: 6px; }
  .products-list .product-layout .button-group .addToCart {
    padding: 7px 5px; }

  .short-by-show {
    text-align: center;
    margin-bottom: 5px;
    margin-top: 7px; }
    .short-by-show .short-by {
      float: none; }
      .short-by-show .short-by select#input-sort {
        width: 100px; }

  .product-filter .box-pagination {
    margin-top: 12px; }

  .short-by-show .form-group {
    margin-bottom: 4px; }

  .product-filter .box-pagination {
    text-align: center; }

  .products-list.grid .product-layout .product-item-container {
    min-height: 200px; }

  div.products-list.grid .product-layout .product-item-container {
    border: 1px solid #ddd !important; }

  /*------------PRODUCT DETAIL ----------------*/
  .thumb-vertical-outer span.view_scroll_spacer a.prev {
    left: 34px; }

  .thumb-vertical-outer span.view_scroll_spacer a.next {
    left: 30px; }

  .left-content-product .content-product-right .box-info-product .add-to-links {
    width: 100%;
    float: left;
    margin-top: 10px; }

  .producttab .tabsslider .nav-tabs li {
    margin-bottom: 0;
    width: 100%; }

  .content-product-left {
    margin: 0 0 20px; }

  /*---------------BLOG -------------*/
  div.blog-item .itemBlogImg {
    float: none;
    margin: 0 0 10px; }
    div.blog-item .itemBlogImg .article-image {
      float: left;
      margin-bottom: 30px; }
    div.blog-item .itemBlogImg a {
      float: none; }

  .blog-listitem .blog-item .itemBlogContent {
    padding: 0; }
    .blog-listitem .blog-item .itemBlogContent .article-description {
      text-align: justify; }
    .blog-listitem .blog-item .itemBlogContent .blog-meta > span.comment_count {
      float: left; }
    .blog-listitem .blog-item .itemBlogContent .blog-meta span.author {
      padding-right: 0; }

  /*------Style About us ------*/
  .about-us .about-content .title strong {
    font-size: 20px; }
  .about-us .about-content .wellcomeshop .media-body {
    overflow: visible;
    float: left;
    margin-top: 20px;
    width: 100%; }
  .about-us .about-content .ourmember.page-heading .pre-text {
    text-align: center;
    padding: 0; }

  .about-us .owl-dots {
    left: 43%; }

  /*----------------- FOOTER ---------------*/
  .module.news-letter {
    margin: 0; }

  .footer-navbar .box-account {
    clear: both; }

  .footer-top {
    height: auto;
    padding: 10px 0; }
    .footer-top .social_block {
      text-align: center;
      height: 70px; }
      .footer-top .social_block ul {
        display: inline-block; }
    .footer-top .news-letter .block_content .input-box input {
      width: 200px; }

  .footer-navbar .box-footer {
    border: 0; }
  .footer-navbar .box-account {
    clear: inherit; }

  .rtl {
    /*------------------ BREADCRUM ---------------*/
    /*----About us -------------*/
    /*---------------BLOG -------------*/ }
    .rtl .header-top ul.dropdown-menu {
      right: auto;
      left: 0; }
    .rtl .header-top-right ul.top-link {
      float: right; }
    .rtl .navbar-header .navbar-toggle {
      right: 10px;
      left: inherit; }
    .rtl .shopping_cart:after {
      left: inherit;
      right: -42px; }
    .rtl .accordion-section .accordion-section-title {
      padding-right: 47px;
      padding-left: 5px; }
    .rtl div.container-slider .item-sub-cat {
      left: 10px;
      right: inherit; }
      .rtl div.container-slider .item-sub-cat ul {
        left: 0;
        right: inherit; }
    .rtl .products-list .product-layout .button-group .addToCart {
      font-size: 9px;
      position: relative;
      top: -2px; }
    .rtl .sidebar-menu-left  .sidebar-megamenu .navbar-default {
      float: right; }
    .rtl .left-content-product .row {
      margin: 0; }
    .rtl .left-content-product .content-product-right .box-info-product .add-to-links li.wishlist a {
      margin-right: 0; }
    .rtl .about-us {
      padding-right: 10px; }
      .rtl .about-us .about-us .owl-dots {
        left: inherit;
        right: 44%; }
      .rtl .about-us .first {
        float: right; }
      .rtl .about-us .title strong {
        padding: 0 0 0 15px; }
      .rtl .about-us .image {
        float: right;
        margin-left: 30px;
        margin-right: 0; }
      .rtl .about-us .about-content .media-left {
        float: right;
        margin-right: 0;
        margin-left: 20px; }
    .rtl div.blog-item .itemBlogImg {
      float: none;
      margin: 0 0 10px; }
      .rtl div.blog-item .itemBlogImg a {
        float: right; } }
/* 5.Extra small devices Phones
================================================== */
@media (max-width: 768px) {
  input[name^="quantity"] {
    min-width: 50px; }

  .producttab .tabsslider.vertical-tabs .tab-content {
    min-height: auto; }

  /*----------------------------- HEADER -----------------------*/
  #header {
    position: relative; }
    #header .header-center {
      padding: 20px 0;
      height: auto; }
    #header .header-bottom-menu .navbar-toggle {
      float: left; }
    #header .header-bottom {
      height: auto; }
    #header .container .header-bottom-inner {
      margin: 0; }
    #header .header-center-right {
      padding: 0;
      width: auto;
      position: absolute;
      top: 0;
      left: 10px;
      z-index: 999; }
      #header .header-center-right .header-bottom-menu {
        padding: 0;
        width: auto; }
    #header .header-bottom .container .header-bottom-inner .header-bottom-right {
      padding: 0; }
    #header .header-center-right {
      top: 4px; }

  .res .collapsed-block .tabBlockTitle {
    margin: 0;
    padding: 2px 0;
    line-height: 32px;
    border-bottom: 1px solid #ddd; }
    .res .collapsed-block .tabBlockTitle .expander {
      cursor: pointer;
      padding: 0 8px;
      font-size: 18px;
      text-decoration: none; }
      .res .collapsed-block .tabBlockTitle .expander i {
        font-size: 12px; }
  .res .collapsed-block .tabBlock {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    box-shadow: 1px 2px 4px #ddd;
    z-index: 99;
    width: 100%;
    padding: 0 15px; }
    .res .collapsed-block .tabBlock .top-link {
      width: 50%;
      float: right;
      margin-bottom: 0; }
      .res .collapsed-block .tabBlock .top-link > li {
        width: 50%;
        float: right;
        text-align: center; }
        .res .collapsed-block .tabBlock .top-link > li.account {
          border-left: 2px solid #ddd; }
    .res .collapsed-block .tabBlock .form-group {
      width: 25%;
      float: right;
      margin: 0; }

  .navbar-logo {
    text-align: center;
    margin-top: 5px;
    width: 100%; }

  .shopping_cart {
    padding: 0 15px;
    text-align: center;
    background: transparent; }
    .shopping_cart .btn-shopping-cart {
      margin: 0 auto; }
    .shopping_cart .shoppingcart-box {
      min-width: 290px; }
    .shopping_cart .shopcart > * {
      display: inline-block; }
    .shopping_cart .handle {
      display: none; }
    .shopping_cart:after {
      display: none; }
    .shopping_cart a.top_cart .shopcart .title {
      float: left; }
    .shopping_cart .btn-shopping-cart a.top_cart {
      padding: 0; }
      .shopping_cart .btn-shopping-cart a.top_cart i {
        font-size: 18px;
        position: relative;
        top: 4px; }
      .shopping_cart .btn-shopping-cart a.top_cart:after {
        display: none; }

  .block-cart {
    float: right;
    background: transparent;
    padding: 0 8px; }
    .block-cart .shopping_cart {
      float: none !important;
      border: 0; }
      .block-cart .shopping_cart .btn-shopping-cart a.top_cart .shopcart .text-shopping-cart {
        float: none; }
      .block-cart .shopping_cart .shoppingcart-box:before {
        right: 0; }
      .block-cart .shopping_cart .dropdown-menu {
        right: 0; }

  .header-top-right ul.top-link > li {
    border-color: #ddd; }
    .header-top-right ul.top-link > li > a {
      color: #666; }

  .header-top .languages-block,
  .header-top .currencies-block {
    border-color: #ddd; }
    .header-top .languages-block form > a,
    .header-top .currencies-block form > a {
      color: #666; }

  #header .header-bottom .container .header-bottom-inner .header_custom_link {
    float: right; }

  #content {
    position: relative;
    overflow: hidden; }

  .header-top {
    min-height: 34px;
   /* background-color: #eee;*/ }
    .header-top .header-top-right ul.top-link {
      float: left;
      width: 100%;
      margin-bottom: 10px; }
    .header-top .languages-block, .header-top .currencies-block {
      float: left; }
    .header-top .languages-block {
      margin-right: 4px;
      border-left: 0;
      border-right: 1px solid #ddd; }

  .header-top-right {
    float: right;
    background-color: #eee;
    margin-bottom: 20px;
    position: relative;
    z-index: 999; }

  #content {
    position: relative;
    overflow: hidden; }

  .right_column {
    float: left;
    display: block;
    width: 100%; }

  /*-------------BLOCK SEARCH --------------*/
  #sosearchpro .dropdown-menu {
    width: 100%; }

  #sosearchpro.search-pro {
    width: 100%;
    margin: 0 0 10px;
    padding: 0 15px; }

  #sosearchpro.so-search .search {
    background: #333;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: #101010; }
    #sosearchpro.so-search .search .autosearch-input {
      border: none;
      color: #ccc; }

  #sosearchpro .select_category {
    background: none; }

  /*----------------BLOCK MAIN MENU -------*/
.navbar-header .navbar-toggle {
  margin-top:0px;
}

 .sidebar-megamenu ul.megamenu > li {
	border-left: 0;
	border-right: 0; 
}

  .header-bottom-menu ul.megamenu {
    text-align: left; }
  .header-bottom-menu .responsive ul.megamenu > li.click:before, .header-bottom-menu .responsive ul.megamenu > li.hover:before,
  .header-bottom-menu .responsive ul.megamenu > li.active .close-menu {
    padding-top: 0; }

  .responsive ul.megamenu .sub-menu .content .row > div {
    border-top: none;
    margin: 0 0 10px; }

  .responsive.sidebar-megamenu .navbar-default .megamenu-wrapper ul.megamenu > li.active:before, .responsive.sidebar-megamenu .navbar-default .vertical-wrapper ul.megamenu > li.active:before {
    display: none; }

  .responsive ul.megamenu > li.click:before, .responsive ul.megamenu > li.hover.active:before {
    content: ""; }

  .responsive ul.megamenu > li.click::before, .responsive ul.megamenu > li.hover::before {
    top: 0; }

  .responsive.sidebar-megamenu .navbar-default .megamenu-wrapper ul.megamenu .sub-menu .content .row, .responsive.sidebar-megamenu .navbar-default .vertical-wrapper ul.megamenu .sub-menu .content .row {
    margin: 0; }
    .responsive.sidebar-megamenu .navbar-default .megamenu-wrapper ul.megamenu .sub-menu .content .row [class^=col], .responsive.sidebar-megamenu .navbar-default .vertical-wrapper ul.megamenu .sub-menu .content .row [class^=col] {
      padding: 0; }

  .sidebar-menu-left .vertical-menu ul.megamenu {
    width: auto;
    position: relative;
    background: none;
    display: block; }

   .sidebar-megamenu .vertical-menu .vertical-wrapper ul.megamenu > li:hover {
    background: none !important; }

  .sidebar-menu-left .vertical-menu .vertical ul.megamenu > li.item-style3 .sub-menu .content {
    padding: 6px; }

  .sidebar-menu-left .vertical-menu .vertical-wrapper ul li > a {
    line-height: 38px; }
    .sidebar-menu-left .vertical-menu .vertical-wrapper ul li > a strong {
      color: #fff; }

  .sidebar-menu-left .vertical-menu:after {
    display: none; }

  .navbar-toggle .icon-bar {
    margin: 0 auto; }

  .responsive.sidebar-megamenu .navbar-default  .sidebar-megamenu-active #remove-megamenu {
    font-size: 20px;
    color: #fff; }
  .responsive.sidebar-megamenu .navbar-default .vertical-wrapper, .responsive.sidebar-megamenu .navbar-default .megamenu-wrapper {
    border: none;
    border-radius: 0;
    background: #333; }
  .responsive.sidebar-megamenu .navbar-default div[class^=col-] {
    width: 100%; }
  .responsive.sidebar-megamenu .navbar-default ul.megamenu > li {
    padding: 3px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.click::before, .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.hover::before {
    padding: 11px 15px !important;
    font-size: 14px;
    background: #f8ab16;
    color: #fff; }
  .responsive.sidebar-megamenu .navbar-default .horizontal ul.megamenu > li.active .close-menu {
    line-height: 50px; }
  .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.active {
    padding: 0; }
    .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.active > a {
      padding: 10px !important;
      background: #222 !important;
      color: #fff; }
    .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.active .close-menu {
      padding: 0;
      width: 41px;
      line-height: 60px;
      background: #f8ab16; }
      .responsive.sidebar-megamenu .navbar-default ul.megamenu > li.active .close-menu:before {
        color: #fff;
        font-size: 12px;
        line-height: 18px; }
  .responsive.sidebar-megamenu .navbar-default .navbar-default .horizontal .megamenu-wrapper ul.megamenu li .sub-menu .content {
    box-shadow: none;
    border: 1px solid #eee; }

  .navbar-default .horizontal .navbar-toggle {
    margin: 0;
    padding: 6px;
    color: #fff;
    border-radius: 0; }
    .navbar-default .horizontal .navbar-toggle .icon-bar {
      background: #fff; }

  .navbar-default .vertical .navbar-toggle {
    margin: 0;
    padding: 6px;
    color: #fff;
    border-radius: 0;
    height: 46px;
    width: 76px; }
    .navbar-default .vertical .navbar-toggle .icon-bar {
      background: #fff; }

  .navbar-default .vertical .megamenu-wrapper, .navbar-default .horizontal .megamenu-wrapper {
    background: #333; }
    .navbar-default .vertical .megamenu-wrapper ul.megamenu > li.active > a, .navbar-default .horizontal .megamenu-wrapper ul.megamenu > li.active > a {
      padding: 5px 10px; }
    .navbar-default .vertical .megamenu-wrapper ul.megamenu > li > a, .navbar-default .horizontal .megamenu-wrapper ul.megamenu > li > a {
      color: #fff;
      padding: 5px 0;
      font-size: 14px; }
      .navbar-default .vertical .megamenu-wrapper ul.megamenu > li > a strong, .navbar-default .horizontal .megamenu-wrapper ul.megamenu > li > a strong {
        color: #fff; }
      .navbar-default .vertical .megamenu-wrapper ul.megamenu > li > a:hover, .navbar-default .horizontal .megamenu-wrapper ul.megamenu > li > a:hover {
        color: #f8ab16; }
    .navbar-default .vertical .megamenu-wrapper ul.megamenu li .sub-menu .content .title-submenu, .navbar-default .horizontal .megamenu-wrapper ul.megamenu li .sub-menu .content .title-submenu {
      padding: 0;
      margin: 0;
      border: none; }
  .navbar-default .vertical .features-menu .column.layout-color a, .navbar-default .horizontal .features-menu .column.layout-color a {
    display: inline-block; }
  .navbar-default .vertical .features-menu .column a, .navbar-default .horizontal .features-menu .column a {
    margin: 0 0 30px;
    display: block; }

  .sidebar-menu-left .vertical-menu ul.megamenu {
    display: block; }

  /*------------------ BREADCRUM ---------------*/
  .breadcrumb li {
    margin-bottom: 5px; }

  #menu.navbar {
    margin: 0; }

  #sosearchpro .dropdown-menu {
    width: 100%; }

  /*----------------- BLOCK SLIDESHOW ---------------------*/
  #so-slideshow {
    width: 100%;
    padding: 0; }
    #so-slideshow .slideshow {
      height: auto; }
    #so-slideshow .slideshow img {
      height: auto; }

  .slider-container .htmlcontent-home {
    display: none; }

  .module.sohomepage-slider .sohomeslider-description .image {
    width: 25%; }

  /*----------------- BLOCK SPOTLIGHT ---------------------*/
  .breadcrumb li {
    margin: 5px 0; }

  /*--------------------MOD-------------------*/
  div.container-slider .categoryslider-content {
    border-color: transparent; }
  div.container-slider .item-sub-cat {
    background: #525252 url("../images/icon/btn-menu-mobile.html") no-repeat scroll center center;
    right: 10px;
    top: 6px;
    cursor: pointer;
    height: 27px;
    position: absolute;
    width: 35px;
    display: inline-block;
    float: right; }
    div.container-slider .item-sub-cat ul {
      background-color: #fff;
      border: 1px solid #ddd;
      display: none;
      padding: 10px 0;
      position: absolute;
      right: 0;
      top: 100%;
      width: 150px;
      z-index: 9999;
      margin: 0; }
      div.container-slider .item-sub-cat ul li {
        display: block;
        float: none;
        margin-left: 5px;
        padding: 0 5px; }
        div.container-slider .item-sub-cat ul li a {
          padding: 0;
          text-transform: capitalize;
          white-space: normal;
          border: 0; }
    div.container-slider .item-sub-cat:hover ul {
      display: block; }
  div.container-slider .products-list.grid .product-layout .product-item-container {
    border: 1px solid #ddd; }

  .accordion-section .accordion-section-title {
    line-height: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px; }

  .module.cus-style-supper-cate .main-left {
    width: 100%; }
    .module.cus-style-supper-cate .main-left .hot-category .category-wrap-cat .slider {
      min-height: auto; }
    .module.cus-style-supper-cate .main-left .banner-post-text {
      display: none; }
  .module.cus-style-supper-cate .so-sp-cat .spcat-tabs-container .spcat-tabs {
    position: static; }
  .module.cus-style-supper-cate .main-right {
    width: 100%; }
    .module.cus-style-supper-cate .main-right .banner-pre-text {
      display: none; }
    .module.cus-style-supper-cate .main-right .spcat-items-container.products-list.grid .product-layout .product-item-container {
      border-width: 1px; }
      .module.cus-style-supper-cate .main-right .spcat-items-container.products-list.grid .product-layout .product-item-container:hover {
        border-width: 1px; }
    .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item {
      width: 50%; }
      .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item .product-item-container {
        min-height: 312px; }
      .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item:nth-child(1) {
        position: static;
        width: 50%; }
        .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item:nth-child(1) .product-item-container {
          min-height: 312px; }
        .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item:nth-child(1) .image {
          margin: 0; }
    .module.cus-style-supper-cate .main-right .spcat-items-container .ltabs-items-inner .ltabs-item {
      width: 50%; }

  .module div.products-list.grid .product-layout .product-item-container .left-block .quickview,
  .module div.products-list.grid .product-layout .product-item-container .button-group button {
    opacity: 1; }

  .module.so-deals div.products-list .product-layout {
    width: 100%; }

  .banner-mid-1 > ul {
    margin: -15px; }
    .banner-mid-1 > ul > li {
      width: 50%;
      padding: 0 15px;
      margin: 0 0 15px; }

  .shopping_cart .shoppingcart-box p.text-right {
    line-height: 18px; }
  .shopping_cart .shoppingcart-box a.btn {
    display: inline-block;
    width: 45%; }
  .shopping_cart .shoppingcart-box .table {
    margin: 0 0 3px; }

  .product-compare .overflow_auto {
    overflow: auto; }

  /*----------------- FOOTER ---------------*/
  .footer-top {
    height: auto;
    padding: 10px 0; }
    .footer-top .social_block {
      text-align: center;
      height: 70px;
      background: transparent; }
      .footer-top .social_block ul {
        display: inline-block; }
    .footer-top .news-letter {
      float: none;
      padding: 0 15px;
      text-align: center; }
      .footer-top .news-letter .title-block {
        margin: 0; }
      .footer-top .news-letter .block_content {
        float: none;
        margin: 15px 0; }
        .footer-top .news-letter .block_content .input-box input {
          width: 410px; }

  .box-footer {
    min-height: 107px;
    margin-bottom: 20px; }
    .box-footer .module h3.modtitle {
      height: auto; }

  .contact-address {
    margin-top: 10px; }

  ul.list-services li {
    margin-bottom: 15px; }

  .footer-navbar .box-account {
    clear: both; }

  .footer-navbar .box-footer {
    border: 0; }

  .table-responsive .btn-block .quaility {
    width: 50px;
    text-align: center;
    padding: 0; }

  .shopping_cart .table > tbody > tr > td:first-child.text-center {
    display: none; }

  div.products-list.grid .product-layout .product-item-container .left-block .product-image-container.second_img .img_0 {
    display: none; }

  div.products-list .product-layout {
    width: 50%; }

  .short-by-show .form-group {
    margin: 0; }
    .short-by-show .form-group:after {
      display: none; }

  /*----------------- PRODUCT DETAIL ---------------------*/
  .producttab .tabsslider .nav-tabs li {
    float: none; }

  .thumb-vertical-outer {
    padding-left: 0; }

  div.left-content-product .content-product-right .box-info-product .add-to-links li.wishlist a {
    margin-left: 6px; }

  .content-product-right .title-product {
    margin: 10px 0; }

  .box-pagination {
    clear: both; }

  .producttab .tabsslider.vertical-tabs ul.nav-tabs {
    min-height: auto; }

  .up-sell-product .so-basic-product .item-wrap {
    box-shadow: none; }
    .up-sell-product .so-basic-product .item-wrap.products-list.grid .product-layout .product-item-container {
      border-color: #ddd; }

  /*---------------BLOG -------------*/
  div.blog-item .itemBlogImg {
    float: none;
    margin: 0 0 10px; }
    div.blog-item .itemBlogImg .article-image {
      float: left;
      margin-bottom: 30px; }
    div.blog-item .itemBlogImg a {
      float: none; }

  .blog-listitem .blog-item .itemBlogContent {
    padding: 0;
    float: left; }
    .blog-listitem .blog-item .itemBlogContent .article-description {
      text-align: justify; }

  .about-us .about-content .media-left {
    float: none; }

  .about-us .about-content .title strong {
    top: -9px; }
  .about-us .about-content .media-left {
    margin-bottom: 20px; }
  .about-us .about-content .whychooseus .title strong {
    top: 12px; }
  .about-us .about-content .ourmember.page-heading .pre-text {
    padding: 0 10px; }
  .about-us .about-content .ourmember.page-heading .title strong {
    top: 13px;
    left: 38%; }

  .our-team-content .owl2-carousel .owl2-item img {
    width: 100%; }

  .yt-pricing .column {
    margin: 0 0 20px; }

  .about-us .owl2-theme .owl2-controls .owl2-dots .owl2-dot span {
    width: 15px;
    height: 15px; }

  div ul.yt-accordion li.accordion-group h3.accordion-heading > span {
    font-size: 13px; }

  .up-sell-product .so-basic-product .item-wrap {
    border: none; }

  .yt-gallery ul.tabnav li {
    margin-bottom: 5px; }

  div.clients_say {
    display: block;
    overflow: hidden; }

  .rtl {
    /*------------------ BREADCRUM ---------------*/
    /*----About us -------------*/ }
    .rtl #header .header-top .languages-block, .rtl #header .header-top .currencies-block {
      float: right; }
    .rtl #header .header-top .languages-block {
      border-left: 1px solid #ddd;
      border-right: 0; }
    .rtl #header .header-center-right {
      left: auto;
      right: 10px; }
    .rtl #header .header-bottom-menu .navbar-toggle {
      float: right; }
    .rtl .header-top-right ul.top-link > li {
      border-left: 0;
      border-right: 1px solid #ddd; }
    .rtl #header .header-bottom-menu .navbar-toggle {
      left: 10px;
      right: inherit;
      float: left; }
    .rtl .table-responsive {
      overflow: overlay !important; }
      .rtl .table-responsive .btn-block .quaility {
        width: 50px;
        text-align: center;
        padding: 0; }
    .rtl .shopping_cart .btn-group {
      text-align: center; }
    .rtl .shopping_cart a.top_cart {
      text-align: center; }
    .rtl .block-cart .shopping_cart .dropdown-menu {
      right: inherit;
      left: 0; }
    .rtl .block-cart .shopping_cart .shoppingcart-box::before {
      right: inherit;
      left: 0; }
    .rtl.product-compare .overflow_auto {
      overflow: auto;
      direction: ltr;
      text-align: right; }
    .rtl #header .header-bottom-menu .navbar-toggle {
      right: 10px;
      left: inherit; }
    .rtl .block-cart,
    .rtl #header .header-bottom .container .header-bottom-inner .header_custom_link {
      float: left; }
    .rtl .sidebar-menu-left  .sidebar-megamenu .navbar-default {
      float: right; }
    .rtl #sosearchpro .dropdown-menu .media-left {
      float: right; }
    .rtl div.container-slider .item-sub-cat {
      left: 10px;
      right: inherit; }
      .rtl div.container-slider .item-sub-cat ul {
        left: 0;
        right: inherit; }
    .rtl .product-detail .left-content-product .content-product-right .box-info-product .add-to-links ul li.wishlist a {
      margin-right: 6px; }
    .rtl .about-us .about-content .first {
      float: none; }
    .rtl .about-us {
      padding-right: 10px; }
      .rtl .about-us .owl-dots {
        left: inherit;
        right: 32%; }
      .rtl .about-us .first {
        float: right; }
      .rtl .about-us .title strong {
        padding: 0 0 0 15px; }
      .rtl .about-us .image {
        float: right;
        margin-left: 30px;
        margin-right: 0; }
    .rtl .about-us .about-content .whychooseus .title strong {
      top: -10px; }
    .rtl .thumb-vertical-outer {
      padding: 0 0 0 10px; }
    .rtl .blog-listitem .blog-item .itemBlogContent .article-sub-title .article-date i {
      margin-right: 0;
      margin-left: 5px; }
    .rtl .blog-listitem .blog-item .itemBlogContent .blog-meta {
      float: right; }
      .rtl .blog-listitem .blog-item .itemBlogContent .blog-meta > span.comment_count {
        float: left; }
      .rtl .blog-listitem .blog-item .itemBlogContent .blog-meta span.author {
        padding-right: 0; }
    .rtl .news-letter .block_content {
      float: right; } }
/* 5.Small devices Phones
================================================== */
@media (max-width: 479px) {
  .module.today_deals .extended_offer > div {
    width: 100%; }

  #header .header-bottom .container .header-bottom-inner .header_search {
    width: 70%; }
  #header .header-bottom .container .header-bottom-inner .block-cart {
    width: 30%; }

  .left-content-product .content-product-left #thumb-slider {
    display: none; }

  ul.yt-accordion li.accordion-group h3.accordion-heading i {
    float: left; }
  ul.yt-accordion li.accordion-group h3.accordion-heading > span {
    display: block !important;
    overflow: hidden;
    padding: 6px 12px !important; }

  .account-register .buttons input.btn {
    margin-top: 10px; }

  .res .collapsed-block .tabBlock .top-link {
    width: 100%;
    float: right;
    margin-bottom: 0;
    border-bottom: 1px solid #ddd; }
    .res .collapsed-block .tabBlock .top-link > li {
      width: 50%;
      float: right;
      text-align: center; }
      .res .collapsed-block .tabBlock .top-link > li.account {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd; }
  .res .collapsed-block .tabBlock .form-group {
    width: 50%;
    float: right;
    margin: 0; }
    .res .collapsed-block .tabBlock .form-group.languages-block {
      border-width: 0 0 0 1px;
      border-color: #ddd;
      border-style: solid; }

  .module.testimonial {
    display: block;
    overflow: hidden; }

  .module.sohomepage-slider .owl2-item.active .sohomeslider-description .text {
    display: none; }
  .module.sohomepage-slider .owl2-item.active .sohomeslider-description .image {
    width: 25%;
    top: 20px; }

  .navbar-default .vertical .navbar-toggle {
    width: 35px; }

  div.clients_say {
    display: block;
    overflow: hidden; }

  .module.cus-style-supper-cate .so-sp-cat .spcat-tabs-container .spcat-tabs-wrap.spcat-selectbox {
    margin: 0 0 20px; }
  .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item {
    width: 100%; }
    .module.cus-style-supper-cate .main-right .spcat-items-container.show-pre .ltabs-items-inner .ltabs-item:nth-child(1) {
      width: 100%; }
  .module.cus-style-supper-cate .main-right .spcat-items-container .ltabs-items-inner .ltabs-item {
    width: 100%; }

  .module.cus-style-supper-cate .so-sp-cat .spcat-tabs-container .spcat-tabs {
    padding: 0; }
    .module.cus-style-supper-cate .so-sp-cat .spcat-tabs-container .spcat-tabs li {
      display: block; }
      .module.cus-style-supper-cate .so-sp-cat .spcat-tabs-container .spcat-tabs li .spcat-tab-label {
        font-size: 12px !important;
        padding: 6px 24px;
        border-bottom: 1px solid #eee; }
      .module.cus-style-supper-cate .so-sp-cat .spcat-tabs-container .spcat-tabs li:last-child .spcat-tab-label {
        border: none; }

  #header .header-top .header-top-right ul.top-link > li > a {
    padding: 0 5px; }

  .breadcrumb li {
    margin-bottom: 5px; }

  #sosearchpro.so-search .select_category {
    display: none; }

  #sosearchpro .dropdown-menu .media-left {
    float: none; }

  .about-us .owl2-theme .owl2-controls .owl2-dots {
    left: 0; }

  .about-us .about-content .ourmember.page-heading .title strong {
    left: 26%; }

  .checkout-cart input.quaility {
    width: 50px; }

  .footer-top .news-letter .block_content .input-box input {
    width: 250px;
    margin-bottom: 10px;
    padding-right: 80px; }
  .footer-top .news-letter .block_content .subcribe {
    position: absolute;
    right: 0; }

  .contact-address li {
    padding: 0 0 5px 41px;
    line-height: 18px; }
  .contact-address span {
    margin-right: 10px;
    height: 30px;
    top: 4px; }
  .contact-address > li:nth-child(2) {
    padding-top: 7px; }
    .contact-address > li:nth-child(2) span {
      margin-top: -1px; }
  .contact-address > li:last-child {
    padding-top: 8px; }
    .contact-address > li:last-child span {
      margin-top: 7px; }

  div.products-list .product-layout {
    width: 100%; }

  .box-footer {
    min-height: 150px; }

  .yt-pricing .column {
    margin: 0 0 20px; }

  .about-image-slider .owl2-stage-outer {
    min-height: 180px; }

  .shopping_cart .shoppingcart-box {
    min-width: 261px; }

  .banner-mid-1 > ul > li {
    display: none; }
    .banner-mid-1 > ul > li:first-child {
      width: 100%;
      display: block; }
      .banner-mid-1 > ul > li:first-child img {
        width: 100%; }

  .so-slider .module.banner {
    margin: 0; }

  .cus-style-supper-cate .so-sp-cat .spcat-tabs-container .spcat-tabs-wrap.spcat-selectbox span.spcat-tab-arrow,
  .cus-style-supper-cate .so-sp-cat .spcat-tabs-container .spcat-tabs-wrap.spcat-selectbox span.spcat-tab-selected {
    border-radius: 0;
    border: 1px solid #ddd; }

  .rtl .table-responsive {
    direction: ltr; }
  .rtl .footer-top .news-letter .block_content .input-box input {
    padding-right: 10px;
    padding-left: 80px; }
  .rtl .footer-top .news-letter .block_content .subcribe {
    right: auto;
    left: 0; }
  .rtl .cus-style-supper-cate .so-sp-cat .spcat-tabs-container .spcat-tabs-wrap.spcat-selectbox span.spcat-tab-selected {
    float: right;
    text-align: right; }
  .rtl #sosearchpro .dropdown-menu .media-left {
    float: none; }
  .rtl ul.yt-accordion li.accordion-group h3.accordion-heading i {
    float: right; }
  .rtl div.article-info .article-sub-title span.article-comment {
    padding-right: 0;
    padding-left: 11px;
    border-left: 0; }
  .rtl .product-detail .left-content-product .content-product-right .box-info-product .add-to-links ul {
    clear: both;
    display: inline-block;
    width: 100%;
    margin-top: 10px; }
    .rtl .product-detail .left-content-product .content-product-right .box-info-product .add-to-links ul li.wishlist a {
      margin-right: 0; }
  .rtl .contact-address li {
    padding: 0 41px 5px 0; }
    .rtl .contact-address li span {
      margin-right: 0; } }


