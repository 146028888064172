// @import "core";
// bootstrap customs only

// .go-menu{
//     position: absolute;
//     background: white;
//     left: 0px;
//     right: 0px;
//     padding: 20px;
//     top: 57px;
//     height: calc( 100vh - 57px);
//     z-index: 9999;
//     &.collapsing {
//       -webkit-transition: none;
//       transition: none;
//       display: none;
//   }
// }

$sidebar-active: rgba(#cb2e2e, 0.1);
$sidebar-hover: rgba(#cb2e2e, 0.05);

$sidebar-active-whitelabel: rgba(#8f00ff, 0.1);
$sidebar-hover-whitelabel: rgba(#8f00ff, 0.05);

.nav-sidebar {
  min-width: rem(258px);
  margin-right: rem(30px);
  @extend .sticky-top;
  top: rem(104px);

  .nav-link {
    @include border-radius(rem(2px));
    color: $gray-dk;
    font-size: rem(14px);
    line-height: rem(20px);
    padding: rem(15px) rem(20px);
    font-weight: 600;

    &:hover {
      color: $gray-dk;
      @include gradient-bg($sidebar-hover);
    }

    .badge {
      @extend .position-relative;
      top: rem(-4px);
    }
  }

  .nav-link.active,
  .show>.nav-link {
    color: $red;
    @include gradient-bg($sidebar-active);
  }
}

.nav-sidebar-whitelabel {
  min-width: rem(258px);
  margin-right: rem(30px);
  @extend .sticky-top;
  top: rem(104px);

  .nav-link {
    @include border-radius(rem(2px));
    color: $gray-dk;
    font-size: rem(14px);
    line-height: rem(20px);
    padding: rem(15px) rem(20px);
    font-weight: 600;

    &:hover {
      color: $gray-dk;
      @include gradient-bg($sidebar-hover);
    }

    .badge {
      @extend .position-relative;
      top: rem(-4px);
    }
  }

  .nav-link.active,
  .show>.nav-link {
    color: #8f00ff;
    @include gradient-bg($sidebar-active);
  }
}

.nav-gotabs {
  border-bottom: 1px solid rgba(#202023, 0.1);

  .nav-link {
    position: relative;
    color: rgba($gray-dk, 0.5);
    font-weight: bold;
    border: 1px solid transparent;
    @include border-radius(4px);
    margin: rem(0 8px 8px 8px);
    // padding: 0;

    &:before {
      content: "";
      position: absolute;
      height: rem(4px);
      left: 0;
      bottom: rem(-10px);
      background-color: $red-1;
      @include border-top-radius(2px);
      opacity: 0;
      width: 0%;
      @include transition(all 0.2s ease);
    }

    &:hover,
    &:focus {
      border-color: $blue;
      outline: 0;
    }

    &.disabled {
      color: $gray-md;
      background-color: transparent;
      border-color: transparent;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $gray-dk;
    background-color: transparent;
    border-color: transparent;

    &:before {
      opacity: 1;
      width: 100%;
      @include transition(all 0.2s ease);
    }
  }

  // .dropdown-menu {
  //   // Make dropdown border overlap tab border
  //   margin-top: -$nav-tabs-border-width;
  //   // Remove the top rounded corners here since there is a hard edge above the menu
  //   @include border-top-radius(0);
  // }
}

.btn {
  text-transform: uppercase;
  min-width: 1rem;
}

.btn-primary {
  position: relative;
  @include boxShadow(0px 2px 5px 0px rgba(221, 58, 58, 0.7));

  &.disabled,
  &:disabled {
    color: #a5a5a5;
    background-color: #e4e4e4;
    border-color: #e4e4e4;
    @include boxShadow(none);
  }

  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    border-color: $white;
    line-height: rem(16px);
    border-width: rem(2px);
    box-shadow: 0 0 0 rem(3px) #4886ff;
  }

  &:not(:focus):not(:disabled):not(.disabled):hover {
    border-color: #e94d4d;
    background-color: #e94d4d;
    @include boxShadow(0px 2px 5px 0px rgba(221, 58, 58, 0.5));
  }

  // &:not(:disabled):not(.disabled):focus:hover,
  &:not(:disabled):not(.disabled):active:hover,
  &:not(:disabled):not(.disabled).active:hover {
    border-color: $white;
    background-color: #e94d4d;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background-color: #d22c2c;
    border-color: #d22c2c;
  }

  // &:after{
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   z-index: -2;
  //   border-radius: rem(4px);
  //   background-color: green;
  //   transform: translateY(10px);
  // }
}

// Accordion css

.gofaq {
  width: rem(720px);

  @include media-breakpoint-down(sm) {
    width: unset;
  }

  .card {
    @include borderRadius(6px);
    border: 1px solid $gray-lt;
    @include boxShadow(0 2px 4px 0 rgba(0, 0, 0, 0.04));
    @extend .mb-3;

    &:hover {
      border: 1px solid $blue;
    }

    &:focus {
      @include boxShadow(0 2px 4px 0 rgba(0, 0, 0, 0.04));
    }
  }

  .card-header {
    @include borderRadius(6px);
    background-color: $white;
    padding: rem(20px);
    @extend .px-4,
    .text-16,
    .font-weight-bold,
    .border-bottom-0,
    .d-flex,
    .justify-content-between;
    color: $gray-dk;
    line-height: rem(20px);

    >i {
      color: $blue;
      @extend .text-14;
    }
  }

  .card-body {
    @extend .text-14, .pt-0, .pl-4;
    padding-bottom: rem(20px);
    padding-right: rem(80px);
    color: $gray-dk;
    line-height: rem(18px);

    @include media-breakpoint-down(sm) {
      padding-right: rem(24px);
    }
  }
}

// Switch Css
.custom-switch {
  .custom-control-label::before {
    background-color: #cecece;
    border-radius: rem(12px);
    border-color: transparent;
  }

  .custom-control-label::after {
    background-color: $white;
  }

  .custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
  }

  .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: transparent;
  }

  .custom-control-input:checked~.custom-control-label::before {
    background-color: $green;
    border-color: $green;
  }
}

.btn-link {
  color: $red;
  font-size: rem(14px);
  font-weight: bold;
  line-height: rem(18px);
  text-decoration: none;
  min-width: rem(61px);
  height: rem(22px);
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $red;
    background-color: rgba(221, 58, 58, 0.1);
    border-radius: 2px;
  }

  &:focus {
    border: 1px solid $blue;
  }
}

.btn-link-secondory {
  color: $blue;

  &:hover,
  &:focus {
    color: $blue;
    background-color: rgba(72, 134, 255, 0.1);
  }
}

//Nav Css
.go-nav {
  .nav-link {
    color: $gray-dk !important;
    font-size: rem(14px);
    font-weight: 600;
    line-height: rem(18px);
    @extend .d-flex, .align-items-center;
    padding: rem(8px 21px) !important;
    border: 1px solid transparent;
    border-radius: rem(16px);

    &:focus {
      border: 1px solid $blue;
      background-color: rgba(203, 46, 46, 0.1);
      outline: none;
      padding: rem(8px 21px);
    }

    &:hover {
      background-color: rgba(#cb2e2e, 0.1);
    }

    &.active {
      background-color: rgba(#cb2e2e, 0.1);
      color: $red !important;
    }

    >i {
      @extend .text-14;
    }
  }
}

.zingworks-nav {
  .nav-link {
    color: $gray-dk !important;
    font-size: rem(14px);
    font-weight: 600;
    line-height: rem(18px);
    @extend .d-flex, .align-items-center;
    padding: rem(8px 21px) !important;
    border: 1px solid transparent;
    border-radius: rem(16px);

    &:focus {
      border: 1px solid $blue;
      background-color: rgba(203, 46, 46, 0.1);
      outline: none;
      padding: rem(8px 21px);
    }

    &:hover {
      background-color: rgba(#8f00ff, 0.1);
    }

    &.active {
      background-color: rgba(#8f00ff, 0.1);
      color: #8f00ff !important;
    }

    >i {
      @extend .text-14;
    }
  }
}

//refer card
.card-refer {
  width: rem(310px);
  padding: rem(32px 20px);
  border: 1px solid #dddddd;
  box-shadow: 0 4px 8px 0 rgba($black, 0.04);
  border-radius: rem(8px);
  -webkit-border-radius: rem(8px);
  -moz-border-radius: rem(8px);
  -ms-border-radius: rem(8px);
  -o-border-radius: rem(8px);

  .card-body {
    padding: 0;

    >img {
      width: rem(60px);
    }

    h4 {
      font-size: rem(16px);
      font-weight: bold;
      line-height: rem(20px);
    }

    p {
      font-size: rem(12px);
      line-height: rem(15px);
      opacity: 0.7;
      margin-bottom: rem(28px);
    }

    .btn-link {
      >img {
        width: rem(14px);
      }
    }
  }
}

// refer code box
.refer-code-box {
  padding: rem(7px 25px);
  margin: rem(0 auto 12px);
  width: rem(191px);
  border: 2px dashed $blue;
  background-color: rgba($white, 0.1);
  border-radius: rem(4px);
  -webkit-border-radius: rem(4px);
  -moz-border-radius: rem(4px);
  -ms-border-radius: rem(4px);
  -o-border-radius: rem(4px);

  span {
    font-size: rem(16px);
    font-weight: 800;
    line-height: rem(20px);
  }

  .copy-icon {
    width: rem(20px);
  }
}

.page-voucher {
  padding-top: rem(48px);

  @include media-breakpoint-down(sm) {
    padding-top: rem(20px);
  }

  .shop-txt {
    color: $gray-dk;
    @extend .text-14;
    font-weight: 600;
    line-height: rem(18px);
  }
}

.modal-all-categories {
  height: calc(100vh - 56px);
  margin-top: rem(56px);
  bottom: 0;
  top: unset;

  .modal-content {
    border-radius: 4px 4px 0 0;
  }

  .modal-header>p {
    color: $gray-dk;
    @extend .text-16;
    font-weight: 800;
    line-height: rem(20px);
  }
}

.nav-category {
  >.nav-link {
    color: rgba($gray-dk, 0.7);
    font-weight: 600;
    line-height: rem(18px);

    i {
      display: none;
    }

    &.active {
      color: $red;
      @extend .d-flex, .justify-content-between;

      i {
        @extend .d-block;
      }
    }
  }
}

//Calender Css
.react-datepicker-wrapper {
  width: 100%;
}

.go-datepicker {
  .react-datepicker__current-month {
    color: $gray-dk;
    font-size: rem(14px);
    line-height: rem(22px);
  }

  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    color: $blue;
  }

  .react-datepicker__header {
    background-color: $white;
  }

  .react-datepicker__day--selected {
    border: 1px solid $blue;
    border-radius: rem(2px);
    background-color: transparent;
    color: $blue;
    font-size: rem(14px);
    line-height: rem(22px);
  }

  .react-datepicker__day:hover {
    border-radius: 2px;
    background-color: rgba($blue, 0.1);
    color: #666666;
    font-size: rem(14px);
    line-height: rem(22px);
  }
}

.search-brand {
  width: 50%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  .input-group-text {
    border: 1px solid #e2e2e2;
  }

  .form-control {
    height: rem(40px);
    border: 1px solid #e2e2e2;
    @extend .text-14;
    line-height: rem(18px);

    &:hover,
    &:focus {
      background-color: $white;
      border: 1px solid #e2e2e2;
    }
  }

  .categories-link {
    background-color: transparent;
    border: 0;
    color: $gray-dk;
    @extend .text-16;
    font-weight: 800;
    line-height: rem(20px);
    text-transform: capitalize;

    &:focus {
      outline: none;
    }

    >i {
      font-size: rem(12px);
    }
  }
}