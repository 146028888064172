.super-admin-layout {
  position: relative;
  height: 100vh;


  .main-admin-content {
    position: absolute;
    top: 16%;
    left: 25%;

  }
}

@media (max-width: 1400px) {
  .super-admin-layout {
    .main-admin-content {
      left: 30%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .super-admin-layout {
    left: 0%;

    .main-admin-content {
      left: 0%;
    }
  }
}