$font-path: '../fonts' !default;

@font-face {
  font-family: 'icomoon';
  src: url('#{$font-path}/icomoon.eot?q3ehsz');
  src: url('#{$font-path}/icomoon.eot?q3ehsz#iefix') format('embedded-opentype'),
    url('#{$font-path}/icomoon.ttf?q3ehsz') format('truetype'),
    url('#{$font-path}/icomoon.woff?q3ehsz') format('woff'),
    url('#{$font-path}/icomoon.svg?q3ehsz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-angle-down:before {
  content: '\e900';
}
.icon-angle-up:before {
  content: '\e901';
}
.icon-arrow-left:before {
  content: '\e902';
}
.icon-arrow-right:before {
  content: '\e903';
}
.icon-close:before {
  content: '\e904';
}
.icon-copy:before {
  content: '\e905';
}
.icon-download:before {
  content: '\e906';
}
.icon-external-link-alt:before {
  content: '\e907';
}
.icon-faq:before {
  content: '\e908';
}
.icon-info-circle .path1:before {
  content: '\e909';
}
.icon-info-circle .path2:before {
  content: '\e90a';
}
.icon-info-circle .path3:before {
  content: '\e90b';
}
.icon-logout:before {
  content: '\e90c';
}
.icon-profile:before {
  content: '\e90d';
}
.icon-redeem:before {
  content: '\e90e';
}
.icon-search:before {
  content: '\e90f';
}
.icon-share:before {
  content: '\e910';
}
.icon-shop:before {
  content: '\e911';
}
.icon-voucher:before {
  content: '\e912';
}
.icon-wallet:before {
  content: '\e913';
}
.icon-check:before {
  content: '\f00c';
}
.icon-bars:before {
  content: '\f0c9';
}
.icon-gift:before {
  content: '\f06b';
}
