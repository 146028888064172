.form-group {
  .react-tel-input {
    width: 100%;

    .selected-flag,
    .flag-dropdown {
      background-color: transparent;
    }
    .selected-flag {
      position: relative;
      width: rem(63px);
      height: 100%;
      padding: 0 rem(12px);
      border: none;
      border-right: 1px solid #dadada;
      background-color: transparent;
      .flag {
        left: rem(22%);
        margin-right: rem(12px);
        &:focus {
          outline: none;
        }
      }
      &:focus {
        outline: none;
      }

      .arrow {
        background: url("../../images/gainon/angle-down@2x.png") no-repeat;
        height: 6px;
        width: auto;
        background-size: auto 6px;
        background-position: right;
        border: 0;
      }
    }
  }

  .react-tel-input input[type="tel"] {
    height: rem(48px);
    padding-left: rem(75px);
    box-shadow: none;
    color: #a5a5a5;
    font-weight: 600;
    line-height: rem(20px);
    border: 1px solid #e2e2e2;
    border-radius: rem(4px);
    background-clip: padding-box;
    &:hover {
      border: 1px solid rgba(72, 134, 255, 0.5);
      background-color: #fcfcfc;
    }
    &:focus {
      color: #333333;
      background-color: #fff;
      border-color: #4886ff;
    }
    &:disabled {
      background-color: #f3f3f3;
    }
  }
}

.auth-icon-box {
  .facebook-auth {
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    box-shadow: rgba($black, 0.24) 0px 2px 2px 0px,
      rgba($black, 0.24) 0px 0px 1px 0px;
    padding: rem(14px 16px);
    font-weight: 500;
    font-size: rem(14px);
    border: 1px solid transparent;
    border-radius: rem(2px);
    -webkit-border-radius: rem(2px);
    -moz-border-radius: rem(2px);
    -ms-border-radius: rem(2px);
    -o-border-radius: rem(2px);
  }
  .google-auth {
    > div {
      margin-right: 0 !important;
    }
  }
}
