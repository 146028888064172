.coupon-category-span9 .row-fluid .block .block-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  
    .pull-left {
      padding: 0;
    }
  
    a {
      background-color: #007bff;
      color: #fff;
      padding: 10px 20px;
      border-radius: 5px;
    }
  }
  
  
  .coupon-category-span9 .form-horizontal .control-group {
    display: flex;
    margin: 30px 0 40px 0;
    justify-content: center;
    align-items: center;
  }
  
  .coupon-category-span9 .form-horizontal .control-group label {
    margin: 0 20px 0 0;
    font-size: 15px;
    font-weight: bold;
  }
  
  .coupon-category-span9 .form-horizontal .control-group .controls {
    width: 40%;
    margin: 0;
  }
  
  .coupon-category-span9 .form-horizontal .control-group .controls .cat-input {
    width: 100%;
    background-color: rgb(232, 240, 254);
    border: none;
    padding: 15px 20px !important;
    border-radius: 5px;
    font-size: 13px;
  }
  
  .coupon-category-span9 .btn-section .controls {
      margin: 0 !important;
  }
  
  .coupon-category-span9 .btn-section .controls .btn::after {
      display: none;
  }
  
  .coupon-category-span9 .btn-section .controls .btn {
      background-color: #c82e29;
      margin: 0 10px;
      text-align: center;
      padding: 0 40px;
  }
  