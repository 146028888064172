@import "../core";

.page-order-completed {
  padding: rem(20px) 0;

  @include media-breakpoint-up(lg) {
    padding: rem(40px) 0;
  }
}

.order-complete-wrap {
  > img {
    width: rem(48px);
    height: auto;
    margin-bottom: rem(20px);
  }

  > h1 {
    line-height: rem(28px);
  }

  > p {
    color: #848484;
    font-weight: 600;
    line-height: rem(20px);
  }
}

.slick-dots li {
  &.slick-active {
    > .abc {
      border: 1px solid $blue;
      color: $blue !important;
      background-color: $white;
    }
  }
}

.slick-next {
  right: rem(40px);
  z-index: 9999;
}

.slick-prev {
  left: rem(40px);
  z-index: 9999;
}

.slick-next:before,
.slick-prev:before {
  content: "\e900";
  font-family: "icomoon" !important;
  display: inline-block;
  font-size: rem(24px);
  opacity: 1;
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.slick-next:before {
  transform: rotate(-90deg);
}

.slick-prev:before {
  transform: rotate(90deg);
}

.slick-list {
  padding-bottom: rem(32px) !important;
  @include media-breakpoint-down(sm) {
    padding-bottom: rem(20px) !important;
  }
}

.transcation-wrap {
  margin-bottom: rem(30px);

  @include media-breakpoint-up(lg) {
    margin-bottom: rem(60px);
  }

  .back-wrap {
    width: unset;
    margin-right: rem(12px);

    @include media-breakpoint-up(lg) {
      width: rem(250px);
      margin-right: 0;
    }

    > a > .icon-arrow-right {
      transform: rotate(180deg);
      color: $gray-dk;
    }
  }

  h2 {
    font-size: rem(14px);
    font-weight: bold;
    line-height: rem(18px);

    @include media-breakpoint-up(lg) {
      font-size: rem(24px);
      font-weight: 800;
      line-height: rem(30px);
    }
  }
}

.card-transaction {
  border: 1px solid #e3e3e3;
  margin-left: 0;
  @include media-breakpoint-up(lg) {
    margin-left: rem(250px);
  }

  .list-group-item {
    &:last-child {
      border-bottom: none;
    }
    > p {
      color: #a5a5a5;
      font-size: rem(14px);
      line-height: rem(18px);
      margin-bottom: 0;
    }
    .btn-success {
      text-transform: capitalize;
      font-size: rem(12px);
      font-weight: bold;
      line-height: 15px;
    }
  }
}

.slick-no-slide .slick-track {
  width: 100% !important;
  text-align: center;
}

.slick-no-slide .slick-slide {
  float: none;
  display: inline-block !important;
}

.slick-slide > div > div {
  &:focus {
    outline: none !important;
    border: 0 !important;
  }
}
