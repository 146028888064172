.admin-header-container {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.admin-header {
  position: relative;
  background-color: #333;
  
  color: #fff;
  .navbar {
    padding: 10px 100px;

    .fa-bars,
    .fa-times {
      display: none;
    }

    img {
      width: 165px;
    }
  }
}

.admin-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.admin-menu span {
  margin: 0 0 0 10px;
}

.admin-dropdown {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 15%;
  transform: translate(0, 100%);

  ul {
    width: 100%;
    li {
      color: #fff;
      background-color: black;
      padding: 15px 0;
      border: 2px solid black;
      border-bottom: 1px solid grey;
      text-align: center;
      &:hover {
        background-color: #fff;
        color: black;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 1600px) {
  .admin-dropdown {
    width: 20%;

    ul li {
      padding: 20px 0;
    }
  }
}

@media (max-width: 1300px) {
  .admin-dropdown {
    width: 25%;
  }
}

@media (max-width: 1050px) {
  .admin-header .navbar .fa-bars,
  .admin-header .navbar .fa-times {
    display: initial;
  }
  .admin-dropdown {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .admin-header {
    .navbar {
      padding: 30px 30px;

      img {
        width: 130px;
        margin: 0;
      }
    }
  }
}
