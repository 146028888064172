@import "../core";

.transtation-wrap {
  margin-bottom: rem(32px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(sm) {
    display: block;
    margin-bottom: rem(24px);
  }

  > p {
    color: $gray-dk;
    font-size: rem(16px);
    line-height: rem(20px);
  }
}

.card-active-voucher {
  @include boxShadow(0 4px 8px 0 rgba(0, 0, 0, 0.04));

  span,
  p,
  .date-time-txt {
    color: $gray-dk;
  }

  span,
  p {
    @extend .text-14;
    font-weight: 600;
    line-height: rem(18px);
  }

  .transaction-id {
    color: $blue;
  }

  .company-logo {
    height: rem(16px);
    width: rem(16px);
    overflow: hidden;
  }

  .date-time-txt,
  .title {
    @extend .text-12;
    line-height: rem(15px);
  }

  .title {
    color: rgba($color: $gray-dk, $alpha: 0.7);
  }

  p > img {
    height: rem(12px);
    width: rem(12px);
  }
}
.coupon-wrap {
  .coupon-img {
    svg {
      width: rem(392px);
      @include media-breakpoint-up(lg) {
        width: rem(300px);
      }
      @include media-breakpoint-up(xl) {
        width: rem(392px);
      }
    }
  }

  @include media-breakpoint-up(xs) {
    overflow-y: auto;
    margin-bottom: rem(48px);
  }
  @include media-breakpoint-up(lg) {
    overflow-y: inherit;
    margin-bottom: rem(70px);
  }
}
.card-balance {
  border: 1px solid #dddddd;
  border-radius: rem(8px);

  @include media-breakpoint-up(lg) {
    border-radius: rem(6px);
  }

  h6 {
    font-size: rem(12px);
    letter-spacing: 0.86px;
    line-height: rem(15px);

    @include media-breakpoint-up(lg) {
      font-size: rem(19px);
      letter-spacing: 1.36px;
      line-height: rem(24px);
    }
  }
  h2 {
    font-weight: 800;
  }
}
.wallet-wrap {
  height: rem(60px);
  width: rem(60px);
  background-color: rgba(#36b355, 0.08);
  overflow: hidden;
  border-radius: 50%;
  padding: rem(14px);

  > i {
    color: #2ac009;
    font-size: rem(30px);
  }
}

.balance-wrap {
  padding: rem(24px) 0;

  @include media-breakpoint-up(lg) {
    padding: rem(20px 0 32px 80px);
  }
  p {
    color: rgba($color: $gray-dk, $alpha: 0.7);
    > .tooltip-img {
      width: rem(14px);
      height: auto;
      margin-top: rem(-2px);
    }
  }
  .rupee-txt {
    font-size: rem(23px);
    line-height: rem(29px);
    color: $gray-dk;
    opacity: 1;
  }
}

.rewards-wrap {
  padding-top: rem(34px);
}

.gainon-ballance-wrap {
  border-bottom: 1px solid rgba($black, 0.11);

  @include media-breakpoint-up(lg) {
    border-bottom: none;
  }
}

.balance-txt {
  @include media-breakpoint-up(lg) {
    border-bottom: 1px solid rgba($black, 0.11);
  }
}

.btn-wrap {
  text-align: center;
  @include media-breakpoint-up(lg) {
    text-align: right;
  }

  > .btn {
    display: block;
    width: 100%;

    @include media-breakpoint-up(lg) {
      display: inline-block;
      width: auto;
    }
  }
}
