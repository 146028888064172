.sc-cSHVUG {
  display: none !important;
}

.hjAmOP {
  padding: 0px 0px 0px 0px;
  .filterComp{
    margin: 0 !important;
    width: 30%;
    .filterComp-input{
      width:84%;
      input{
        border-top-right-radius:0 !important;
        border-bottom-right-radius:0 !important;
        box-shadow: none;
        font-size: 11px;
      }
    }
    button{
      width: 15%;
      border-left:0;
      border-top-right-radius:.25rem !important;
      border-bottom-right-radius:.25rem !important;
      background-color: #c82e29;
      color: #fff;
      font-weight: bold;
    }
  }
}

.coupon-category-span9 .row-fluid .block .block-header  {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.coupon-category-span9 .row-fluid .block .block-header {
    padding: 0px 20px;
}

.coupon-category-span9 .row-fluid .block .block-header .pull-left {
    padding: 0;
}

.coupon-category-span9 .row-fluid .block .block-header .pull-right {
    padding: 0
}

.coupon-category-span9 .row-fluid .block .block-header .pull-right a {
  text-align: center;
  padding: 0 20px;
  margin: 10px 0;
  background-color: #c82e29 !important;
}


.coupon-category-span9 .row-fluid .block .block-header .pull-right a::after {
    display: none;
}

.rdt_Pagination{
  padding-top:10px;
}

@media (max-width: 1500px) {
  .coupon-category-span9 {
    max-width: 900px !important;
  }
}

@media (max-width: 1300px){
    .coupon-category-span9 {
        max-width: 800px !important;
    }
}