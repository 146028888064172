$sidebar-active: rgba(#cb2e2e, 0.1);
$sidebar-hover: rgba(#cb2e2e, 0.05);
$white: #fff !default;

.zing-border-red {
    border: 1px solid red !important;
}

.zing-btn-container {}

.zing-btn {
    display: block;
    padding: 6px;
    width: 100%;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    text-transform: uppercase;
}

.zing-btn-sm {
    display: block;
    padding: 4px;
    width: 100%;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    text-transform: uppercase;
}

.zing-btn-width-small {
    width: 10% !important;
}

.zing-btn-primary {

    box-shadow: 0px 2px 5px 0px rgba(221, 58, 58, 0.7);
    background-color: rgba(221, 58, 58, 0.7);
    color: $white;

    &:hover {
        background-color: rgba(221, 58, 58, 1);
    }
}

.zing-card {}

.zing-card .zing-card-header {
    font-size: 16px;
    font-weight: bold;
}

.zing-form-label {
    padding: 2px;
    width: 100%;
    font-size: 12px;
}

.zing-form-control {
    width: 100% !important;
    height: 34px !important;
    // font-size: 16px !important;
    // padding: 0px 6px !important;
}


@media only screen and (min-width: 768px) {
    .zing-container {
        width: 700px !important;
        margin-left: -180px !important;

    }
}

@media only screen and (min-width: 992px) {
    .zing-container {
        width: 800px !important;
        margin-left: -200px !important;

    }
}

@media only screen and (min-width: 1200px) {
    .zing-container {
        width: 900px !important;
        margin-left: -40px !important;

    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}