.download-button {
    background-color: #c82e29;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .download-button:hover {
    background-color: #a0221e;
  }

.header-center {
    text-align: center;
}

.date-picker-center{
    margin-left: 15px !important;
}