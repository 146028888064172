.sc-cSHVUG {
  display: none !important;
}

.hjAmOP {
  padding: 0px 0px 0px 0px;
  .filterComp {
    margin: 0 !important;
    width: 30%;
    .filterComp-input {
      width: 84%;
      input {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        box-shadow: none;
        font-size: 11px;
      }
    }
    button {
      width: 15%;
      border-left: 0;
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
      background-color: #c82e29;
      color: #fff;
      font-weight: bold;
    }
  }
  
}

.brand-coupon .row-fluid .block .block-header {
  padding: 0px 20px !important;
}

.brand-coupon .row-fluid .block .block-header .pull-left {
  padding: 0;
}

.brand-coupon .row-fluid .block .block-header .pull-right {
  padding: 0;
}

.brand-coupon .row-fluid .block .block-header .pull-right a {
  text-align: center;
  padding: 0 20px !important;
  margin: 10px 0;
  background-color: #c82e29 !important;
}

@media (max-width: 1500px) {
  .brand-coupon {
    max-width: 900px !important;
  }
}

@media (max-width: 1300px) {
  .brand-coupon {
    max-width: 800px !important;
  }
}
